import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
};

export const offcanvasSlice = createSlice({
  name: 'offcanvas',
  initialState,
  reducers: {
    toggle: (state) => {
      state.show = !state.show;
    },
    close: (state) => {
      state.show = false;
    },
  },
});

export const { toggle, close } = offcanvasSlice.actions;
export const selectShow = (state) => state.offcanvas.show;
export default offcanvasSlice.reducer;
