import React from 'react';
import { Button, Spinner, Row, Col, Navbar, Image, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { selectEventInfo, setProgress } from '../../../checkout.slice';
import { confirmOrder, getDownloadTickets, selectLoading, selectLoadingDownloadTickets, setLoadingDownloadTickets } from '../order.slice';
import { getUpsellings, selectUpsellings } from '../../cart-info/cart-info.slice';
import queryString from 'query-string';
import { selectUser } from '../../auth/auth.slice';
import { showAlert } from '../../alert/alert.slice';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from "react-icons/bs";


export default function OrderSuccess() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const loading = useSelector(selectLoading);
  const loadingDownloadTickets = useSelector(selectLoadingDownloadTickets);
  const user = useSelector(selectUser);
  const eventInfo = useSelector(selectEventInfo);
  const eventSession = queryParams?.eventSession;
  const orderId = queryParams?.r;
  const upsellings = useSelector(selectUpsellings);

  React.useEffect(() => {
    dispatch(setProgress(100));
  }, [dispatch]);

  React.useEffect(() => {
    const payload = {
      order_onebox_id: orderId,
      event_session_id: eventSession,
    }

    dispatch(confirmOrder(payload)).then((response) => {
      if (response?.error || response?.payload?.status !== 'success') {
        if (Array.isArray(response?.payload)) dispatch(showAlert({ variant: 'danger', message: response?.payload?.join(', ') }));
        else dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.PURCHASE_FAILED") }));
        history.push("/error?eventSession=" + eventSession);

      } else {
        dispatch(getUpsellings({ eventId: eventInfo?.eventId, external: true }));

        // Si viene el queryparam 'i' quiere decir que viene de la entidad bancaria y acaba de realizar el pago, si el confirm es success se añade el evento purchase al datalayer
        if (queryParams.i) {
          let cartItems = [];

          if (!response?.payload.shopping_cart['shopping-cart-item']) {
            cartItems = [];

          } else if (Array.isArray(response?.payload.shopping_cart['shopping-cart-item'])) {
            cartItems = response?.payload.shopping_cart['shopping-cart-item'];

            // If single comes as object
          } else {
            cartItems = [response?.payload.shopping_cart['shopping-cart-item']];
          }

          const cartItemsFormatted = cartItems.map(item => {
            return {
              item_id: item['@id'],
              // item_category: item['@type'],
              price: item['price-breakdown']['final-price'],
              quantity: '1'
            }
          });

          const upsellingsFormatted = response.payload?.upselling_variations.map(id => {
            return {
              item_id: id
            }
          });

          const items = cartItemsFormatted.concat(upsellingsFormatted);

          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: orderId,
              currency: response.payload.shopping_cart['@currency'],
              value: Number(response.payload.amount).toFixed(2),
              tax: Number((Math.round(response.payload.shopping_cart['total-price-breakdown']?.charges?.charge?.reduce((acumulator, currentValue) => ({ value: Number(acumulator.value) + Number(currentValue.value) }), { value: 0 })?.value * 100) / 100)).toFixed(2),
              coupon: response.payload?.shopping_cart?.client?.groupValidation?.promotionalCode || '',
              items: items
            }
          });

          // Eliminamos el queryparam 'i' para evitar volver a añadir el evento purchase y duplicar la compra
          setTimeout(() => {
            delete queryParams.i;
            history.replace({ search: queryString.stringify(queryParams) });
          }, 0);
        }
        // dispatch(showAlert({ variant: 'success', message: t("ALERT.SUCCESS.PURCHASE_COMPLETED") }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function downloadTickets() {
    dispatch(setLoadingDownloadTickets(true));
    let iterations = 15;

    getTickets();
    const interval = setInterval(() => { getTickets(); }, 2000);

    function getTickets() {
      iterations -= 1;

      dispatch(getDownloadTickets(orderId)).then((response) => {
        if (iterations === 0 && response?.payload?.status === 204) {
          clearInterval(interval);
          dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.DOWNLOAD_FAILED") }));
          dispatch(setLoadingDownloadTickets(false));
        }
        if (response?.payload?.status !== 204) {
          clearInterval(interval);
          dispatch(setLoadingDownloadTickets(false));
        }
        if (response?.error) {
          dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.DOWNLOAD_FAILED") }));
          dispatch(setLoadingDownloadTickets(false));

        } else if (response?.payload?.mergedTickets) {
          const loadingTab = window.location.href = response?.payload?.mergedTickets;
          try {
            if (loadingTab) loadingTab?.focus();
          } catch (error) { }
          clearInterval(interval);
          dispatch(setLoadingDownloadTickets(false));
        }
      });
    }
  }

  return (
    <>
      <div className="mt-4 mb-4 mt-lg-4 mb-lg-5">
        {loading ?
          <>
            <div className="text-center fw-bold">
              <div className="mt-3 lead">{t("ORDER.SUCCESS.PROCESSING_TICKETS_1")}</div>
              <div className="mb-3 lead">{t("ORDER.SUCCESS.PROCESSING_TICKETS_2")}</div>
              <div className="d-flex" style={{ height: "98%" }}>
                <Spinner className="mx-auto align-self-center" as="span" animation="grow" size="lg" role="status" aria-hidden="true" />
              </div>
            </div>
          </>
          :
          <>
            <div className="text-center fw-bold">
              <Link className="float-start cursor-pointer text-dark text-decoration-none" to={"/?eventSession=" + queryParams?.eventSession}><BsChevronLeft className="text-dark" /></Link>
              <h6 className="pe-2">{t("ORDER.SUCCESS.TITLE")}</h6>
              <div className="mt-5">
                {eventInfo?.channel?.logo && <Image src={eventInfo?.channel?.logo} width="250" className="mb-5 mt-3" />}
                <h1>{t("ORDER.SUCCESS.YOU_GO_TO", { value: eventInfo?.artist + ' · ' + eventInfo?.name })}</h1>
                <div className="text-muted mt-4">{t("ORDER.SUCCESS.PAYMENT_TEXT")}</div>
                <span className="text-muted">{t("ORDER.SUCCESS.DOWNLOAD_TEXT")} </span>
                <span><b>{user?.email}</b>.</span>
                <div className="d-none d-lg-block">
                  <Button variant="dark" size="lg" className="shadow flex-fill mt-4" onClick={() => downloadTickets()} disabled={loadingDownloadTickets}>
                    {loadingDownloadTickets ?
                      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      :
                      <>{t("ORDER.SUCCESS.BUTTON.DOWNLOAD")}</>
                    }
                  </Button>
                </div>
              </div>
            </div>

            {/* SERVICIOS ADICIONALES */}
            {Boolean(upsellings?.length) &&
              <div className='mt-5'>
                <h6 className='pt-5 mb-4'>{t("SERVICES.TITLE")}</h6>

                <Row>
                  {upsellings?.map((item, i) => {
                    return <Col xxl={6} key={i} className='mb-3'>
                      <Card>
                        <Card.Body>
                          <div className='d-flex justify-content-between fw-bold'>
                            <h6>{item.name}</h6>
                            {item.external_price && <div className='d-none d-sm-block'>{item.external_price} €</div>}
                          </div>

                          <div className='d-flex justify-content-between'>
                            <div>
                              <div className='text-muted me-3'>{item.description}</div>
                              {item.external_price && <div className='mt-3 d-sm-none fw-bold'>{item.external_price} €</div>}
                              <div className='mt-3 d-none d-sm-block'>
                                <Card.Link href={item.external_link} rel="noreferrer" target='_blank' className='cursor-pointer text-dark text-decoration-none fw-bold'>{t('SERVICES.MORE_INFO')}</Card.Link>
                              </div>
                            </div>
                            <div className='d-flex align-items-end d-none d-sm-flex' style={{ minWidth: '125px' }}>
                              <a href={item.external_link} rel="noreferrer" target='_blank' className="btn btn-dark shadow flex-fill" style={{ fontSize: '15px' }} role="button">{t("SERVICES.TO_BOOK_HERE")}</a>
                            </div>
                          </div>

                          {/* Download link for mobile */}
                          <div className='mt-3 d-sm-none'>
                            <a href={item.external_link} rel="noreferrer" target='_blank' className="btn btn-dark shadow flex-fill" style={{ fontSize: '15px' }} role="button">{t("SERVICES.TO_BOOK_HERE")}</a>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  })}
                </Row>
              </div>
            }

            <Row>
              <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
                <Button variant="dark" size="lg" className="shadow flex-fill" onClick={() => downloadTickets()} disabled={loadingDownloadTickets}>
                  {loadingDownloadTickets ?
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    :
                    <>{t("ORDER.SUCCESS.BUTTON.DOWNLOAD")}</>
                  }
                </Button>
              </Navbar>
            </Row>

          </>
        }

      </div>
    </>
  );
}