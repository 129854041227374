import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { setProgress } from '../../checkout.slice';
import Login from './login/login';
import Register from './register/register';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from "react-icons/bs";
import queryString from 'query-string';

export default function Auth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLogin = history.location.pathname === '/login';
  const isRegister = history.location.pathname === '/register';
  const queryParams = queryString.parse(history.location.search);

  React.useEffect(() => {
    dispatch(setProgress(32));
  }, [dispatch]);

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <Link className="float-start cursor-pointer text-dark text-decoration-none" to={"/?eventSession=" + queryParams?.eventSession}><BsChevronLeft className="text-dark" /></Link>
        {isLogin && <h6 className="pe-2">{t("AUTH.TITLE.SIGN_IN")}</h6>}
        {isRegister && <h6 className="pe-2">{t("AUTH.TITLE.SIGN_UP")}</h6>}
      </div>

      <div className="align-self-center">
        <Link to={"login" + history.location.search} className={"text-dark text-decoration-none" + (isLogin ? " fw-bold" : " text-black-50")}>{t("AUTH.TABS.SIGN_IN")}</Link>
        <Link to={"register" + history.location.search} className={"text-dark text-decoration-none" + (isRegister ? " fw-bold" : " text-black-50 ") + " float-end"}>{t("AUTH.TABS.SIGN_UP")}</Link>

        <hr />

        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
        </Switch>

      </div>
    </>
  );
}
