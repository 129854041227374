import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Checkout from './features/checkout/checkout';
import { getUserLocalStorage } from './services/auth';
import { useDispatch } from 'react-redux';
import { setUser } from './features/checkout/components/auth/auth.slice';
import { setUpsellings } from './features/checkout/components/cart-info/cart-info.slice';
import queryString from 'query-string';
import './App.scss';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5294P6V'
}

TagManager.initialize(tagManagerArgs);

function App() {
  const dispatch = useDispatch();

  // Set user to redux from localStorage
  const user = getUserLocalStorage();
  if (user) dispatch(setUser(user));

  // Set upsellings to redux from localStorage
  const queryParams = queryString.parse(window.location.search);
  const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
  const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
  const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
  if (currentCartSession?.upsellings) dispatch(setUpsellings(currentCartSession.upsellings));

  return (
    <>
      <Suspense fallback="loading">
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Checkout />
            </Route>
            <Route path="/login">
              <Checkout />
            </Route>
            <Route path="/register">
              <Checkout />
            </Route>
            <Route path="/forgot">
              <Checkout />
            </Route>
            <Route path="/recover">
              <Checkout />
            </Route>
            <Route path="/account">
              <Checkout />
            </Route>
            <Route path="/services">
              <Checkout />
            </Route>
            <Route path="/payment">
              <Checkout />
            </Route>
            <Route path="/order">
              <Checkout />
            </Route>
            <Route path="/success">
              <Checkout />
            </Route>
            <Route path="/error">
              <Checkout />
            </Route>
            <Route path="*">
              {/* TODO: 404 component */}
              404
            </Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
