import React from 'react';
import { Button, Row, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setProgress } from '../../../checkout.slice';
import { toggle } from '../../../parts/offcanvas/offcanvas.slice';
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../../cart-info/cart-info.slice';
import { useTranslation } from 'react-i18next';
import { selectOrderError } from '../order.slice';

export default function OrderError() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const orderError = useSelector(selectOrderError);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartUpsellings = useSelector(selectCartUpsellings);

  React.useEffect(() => {
    dispatch(setProgress(80));
  }, [dispatch]);

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <h6>{t("ORDER.ERROR.TITLE")}</h6>
        <div className="lead mt-3 mb-3">{orderError}</div>
        <div className="d-none d-lg-block">
          <Button variant="dark" size="lg" className="shadow flex-fill mt-3" onClick={() => history.push('/' + history.location.search)}>{t("ORDER.ERROR.BUTTON.BACK")}</Button>
        </div>

        <Row>
          <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
            {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
            <Button variant="dark" size="lg" className={"shadow flex-fill"} onClick={() => history.push('/' + history.location.search)}>{t("ORDER.ERROR.BUTTON.BACK")}</Button>
          </Navbar>
        </Row>
      </div>
    </>
  );
}