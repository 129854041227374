import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, FloatingLabel, Button, Spinner, Navbar } from 'react-bootstrap';
import { Formik, ErrorMessage } from "formik";
import { useSelector } from 'react-redux';
import { passwordRecovery, selectLoading } from '../auth.slice';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import * as Yup from "yup";
import { toggle } from '../../../parts/offcanvas/offcanvas.slice';
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../../cart-info/cart-info.slice';
import { showAlert } from '../../alert/alert.slice';
import { useTranslation } from 'react-i18next';

export default function Recover() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectLoading);
  const queryParams = queryString.parse(history.location.search);
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartUpsellings = useSelector(selectCartUpsellings);

  const validationSchema = Yup.object({
    password: Yup.string().required(),
    repeatPassword: Yup.string().required(),
  });

  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  const onSubmit = async (values, { setErrors, resetForm }) => {
    if (values.password !== values.repeatPassword) {
      setErrors({ repeatPassword: "La contraseña no coincide" });
      return;
    }
    const payload = {
      email: queryParams?.email,
      token: queryParams?.token,
      password: values.password
    }
    dispatch(passwordRecovery(payload)).then((response) => {
      if (response?.error) {
        setErrors(response?.payload);
        dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.FORM_INVALID") }));

      } else {
        history.push("/account?eventSession=" + queryParams?.eventSession);
      }
    });
  };

  const renderError = (message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>;

  return (
    <>

      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <h6>{t("RECOVER.TITLE")}</h6>
      </div>

      <span>{t("RECOVER.EMAIL_ADDRESS")}: {queryParams?.email}</span>
      <hr />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Row>
              <Col md={6}>
                <FloatingLabel controlId="password" label={t("RECOVER.FORM.INPUT.PASSWORD")} className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder={t("RECOVER.FORM.INPUT.PASSWORD")}
                    value={values.password}
                    isInvalid={touched?.password && !!errors?.password}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="password" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId="repeatPassword" label={t("RECOVER.FORM.INPUT.REPEAT_PASSWORD")} className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder={t("RECOVER.FORM.INPUT.REPEAT_PASSWORD")}
                    value={values.repeatPassword}
                    isInvalid={touched?.repeatPassword && !!errors?.repeatPassword}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="repeatPassword" render={renderError} />
                </FloatingLabel>
              </Col>
            </Row>

            <Button variant="dark" size="lg" type="submit" className="d-none d-lg-block" disabled={loading}>
              {loading ?
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                :
                <>{t("RECOVER.FORM.BUTTON.SUBMIT")}</>
              }
            </Button>

            <Row>
              <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
                {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
                <Button variant="dark" size="lg" type="submit" className="shadow flex-fill" disabled={loading}>
                  {loading ?
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    :
                    <>{t("RECOVER.FORM.BUTTON.SUBMIT")}</>
                  }
                </Button>
              </Navbar>
            </Row>

          </Form>
        )}
      </Formik>
    </>
  );
}
