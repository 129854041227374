import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, FloatingLabel, Button, Spinner, Navbar } from 'react-bootstrap';
import { Formik, ErrorMessage } from "formik";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectLoading, getPasswordRecovery } from '../../auth/auth.slice';
import { setProgress } from '../../../checkout.slice';
import queryString from 'query-string';
import * as Yup from "yup";
import { toggle } from '../../../parts/offcanvas/offcanvas.slice';
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../../cart-info/cart-info.slice';
import { showAlert } from '../../alert/alert.slice';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from "react-icons/bs";

export default function Forgot() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectLoading);
  const [sended, setSended] = React.useState(false);
  const queryParams = queryString.parse(history.location.search);
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartUpsellings = useSelector(selectCartUpsellings);

  React.useEffect(() => {
    dispatch(setProgress(32));
  }, [dispatch]);

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, { setErrors, resetForm }) => {
    const payload = {
      email: values.email,
      eventSession: queryParams?.eventSession
    }
    dispatch(getPasswordRecovery(payload)).then((response) => {
      if (response?.error) {
        setErrors(response.payload);
        dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.FORM_INVALID") }));

      } else {
        setSended(true);
        dispatch(showAlert({ variant: 'success', message: t("ALERT.SUCCESS.CHANGES_SAVED") }));
      }
    });
  };

  const renderError = (message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>;

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <Link className="float-start cursor-pointer text-dark text-decoration-none" to={"/login" + history.location.search}><BsChevronLeft className="text-dark" /></Link>
        <h6 className="pe-2">{t("FORGOT.TITLE")}</h6>
      </div>

      {sended ?
        <>
          <hr />
          <span>{t("FORGOT.SENDED")}</span>
        </>
        :
        <>
          <div>{t("FORGOT.STEP_INFO_1")}</div>
          <div className="mb-4">{t("FORGOT.STEP_INFO_2")}</div>
          <hr />

          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col lg={6}>
                      <FloatingLabel controlId="email" label={t("FORGOT.FORM.INPUT.EMAIL")} className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder={t("FORGOT.FORM.INPUT.EMAIL")}
                          value={values.email}
                          isInvalid={touched?.email && !!errors?.email}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="email" render={renderError} />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <Button type="submit" variant="dark" size="lg" className="d-none d-lg-block" disabled={loading}>
                    {loading ?
                      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      :
                      <>{t("FORGOT.FORM.BUTTON.SUBMIT")}</>
                    }
                  </Button>

                  <Row>
                    <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
                      {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
                      <Button type="submit" variant="dark" size="lg" className="shadow flex-fill" disabled={loading}>
                        {loading ?
                          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                          :
                          <>{t("FORGOT.FORM.BUTTON.SUBMIT")}</>
                        }
                      </Button>
                    </Navbar>
                  </Row>

                </Form>
              )}
            </Formik>
          </>
        </>
      }
    </>
  );
}
