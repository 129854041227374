import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spinner } from 'react-bootstrap';
import { selectCartItems, selectPromotions, selectCharges, selectFinalPrice, selectCartInsurance, deleteCartItem, deleteCartVariation, selectCartInsuranceCost, selectHasInsurance, selectCartUpsellings, selectUpsellings } from './cart-info.slice';
import { selectEventInfo, selectSessionInfo } from '../../checkout.slice';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import deleteIcon from '../../../../assets/images/delete.svg';
// import CartPromotion from '../cart-promotion/cart-promotion';

export default function CartInfo(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { headerClasses } = props;
  const history = useHistory();
  const cartItems = useSelector(selectCartItems);
  const promotions = useSelector(selectPromotions);
  const charges = useSelector(selectCharges);
  const finalPrice = useSelector(selectFinalPrice);
  const eventInfo = useSelector(selectEventInfo);
  const sessionInfo = useSelector(selectSessionInfo);
  const cartInsurance = useSelector(selectCartInsurance);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartUpsellings = useSelector(selectCartUpsellings);
  const upsellings = useSelector(selectUpsellings);
  const [loadingDeleteItemId, setLoadingDeleteItemId] = React.useState('');

  function deleteItem(id) {
    setLoadingDeleteItemId(id);
    const payload = {
      shopping_cart_items: [id]
    }
    dispatch(deleteCartItem(payload)).then(response => {
      setLoadingDeleteItemId('');
    });
  }

  function deleteVariation(id) {
    setLoadingDeleteItemId(id);
    const payload = {
      cartUpsellings,
      id
    }
    dispatch(deleteCartVariation(payload));
    setLoadingDeleteItemId('');
  }

  return (
    <>
      <div className={headerClasses}>
        <h3>{eventInfo?.artist + ' · ' + eventInfo?.name}</h3>
        <div className="fw-light text-muted mb-1">{format(new Date(sessionInfo?.datetime), "d 'de' MMMM 'de' yyyy · HH:mm'h'", { locale: es, timeZone: 'Europe/Madrid' })}</div>
        <div className="fw-light text-muted mb-4 text-capitalize">{eventInfo?.venue}</div>
        <hr />
      </div>

      <Table borderless responsive>
        <thead>
          <tr>
            <th className="fw-light text-muted" style={{ fontSize: '12px' }}>{t("CART_INFO.TABLE.HEADER.TYPE")}</th>
            <th className="fw-light text-muted" style={{ fontSize: '12px' }}>Sector</th>
            <th className="fw-light text-muted" style={{ fontSize: '12px' }}>{t("CART_INFO.TABLE.HEADER.ROW")}</th>
            <th className="fw-light text-muted" style={{ fontSize: '12px' }}>{t("CART_INFO.TABLE.HEADER.SEAT")}</th>
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((item, i) => {
            return <tr key={i}>
              <td><b>{item?.ticketData?.ticketType}</b></td>
              <td className="text-muted">{item?.ticketData?.sectorName?.replace('Sector ', '')}</td>
              <td className="text-muted">{item?.ticketData?.rowName}</td>
              <td className="text-muted">{item?.ticketData?.numSeat}</td>
              <td className="text-muted d-flex justify-content-end">{(Math.round(item?.priceBreakdown?.basePrice * 100) / 100).toFixed(2)}€</td>
              <td className="text-muted">
                {history.location.pathname !== '/' && (
                  loadingDeleteItemId === item?.id ?
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    :
                    <span className="fw-bold text-dark cursor-pointer" onClick={() => deleteItem(item?.id)}>
                      <img src={deleteIcon} width="10" style={{ paddingBottom: '2px' }} alt="delete" />
                    </span>
                )}
              </td>
            </tr>
          })}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {promotions && !!Number(promotions) && <tr>
            <td colSpan="4" className="text-muted">{t("CART_INFO.TABLE.PROMOTIONS")}</td>
            <td className="text-muted d-flex justify-content-end">-{promotions}€</td>
            <td></td>
          </tr>}
          {!hasInsurance ? null : cartInsurance?.map((service, i) => {
            return <tr key={i}>
              <td colSpan="4" className="text-muted">{service?.name}</td>
              <td className="text-muted d-flex justify-content-end">{cartInsuranceCost}€</td>
              <td></td>
            </tr>
          })}
          {!cartUpsellings?.length ? null : cartUpsellings?.map((variation, i) => {
            return <tr key={i}>
              <td colSpan="4" className="text-muted">{variation?.quantity + 'x ' + upsellings?.find(_upselling => _upselling?.variations?.some(_variation => _variation.id === variation.id))?.name + ' - ' + variation?.name}</td>
              <td className="text-muted d-flex justify-content-end">{(Number(variation.price) * Number(variation.quantity)).toFixed(2)}€</td>
              <td className="text-muted">
                {history.location.pathname !== '/' && (
                  loadingDeleteItemId === variation?.id ?
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    :
                    <span className="fw-bold text-dark cursor-pointer" onClick={() => deleteVariation(variation?.id)}>
                      <img src={deleteIcon} width="10" style={{ paddingBottom: '2px' }} alt="delete" />
                    </span>
                )}
              </td>
            </tr>
          })}
          <tr>
            <td colSpan="4" className="text-muted">{t("CART_INFO.TABLE.FEES")}</td>
            <td className="text-muted d-flex justify-content-end">{charges}€</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="4"><b>{t("CART_INFO.TABLE.TOTAL")}</b></td>
            <td className="d-flex justify-content-end"><b>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)}€</b></td>
            <td></td>
          </tr>
        </tbody>
      </Table>

      {/* <CartPromotion /> */}
    </>
  );
}
