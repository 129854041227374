import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';

const initialState = {
  token: "",
  timeLeft: 0,
  cartItems: [],
  charges: 0,
  promotions: 0,
  cartPromotions: '',
  basePrice: 0,
  finalPrice: 0,
  cartInsurance: [],
  cartInsuranceCost: 0,
  insurance: null,
  hasInsurance: false,
  upsellings: [],
  cartUpsellings: [],
  loadingGetUpsellings: false,
  loadingGetInsurance: false,
  loadingAddInsurance: false,
  loadingAddPromotionCode: false,
  loadingDeleteInsurance: false,
};

export const getShoppingCart = createAsyncThunk('cartInfo/shoppingCartDetail', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    const response = await axios.get(`/shopping_carts/${shoppingCartToken}`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// get Upsellings
export const getUpsellings = createAsyncThunk('cartInfo/getUpsellings', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/events/${payload.eventId}/upsellings` + (payload.external ? '?is_external=1' : ''));
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const getInsurance = createAsyncThunk('cartInfo/getInsurance', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    if (!shoppingCartToken) return;
    const response = await axios.get(`/shopping_carts/${shoppingCartToken}/insurances`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> insurance_id
export const addInsurance = createAsyncThunk('cartInfo/addInsurance', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    const response = await axios.post(`/shopping_carts/${shoppingCartToken}/insurances`, payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> insurance_id
export const deleteInsurance = createAsyncThunk('cartInfo/deleteInsurance', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    const response = await axios.delete(`/shopping_carts/${shoppingCartToken}/insurances`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> shopping_cart_items
export const deleteCartItem = createAsyncThunk('cartInfo/deleteCartItem', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    const response = await axios.post(`/shopping_carts/${shoppingCartToken}/release_items`, payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> promotional_code
export const addPromotionCode = createAsyncThunk('cartInfo/addPromotionCode', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    const response = await axios.post(`/shopping_carts/${shoppingCartToken}/promotions`, payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> promotion_id
export const deletePromotionCode = createAsyncThunk('cartInfo/deletePromotionCode', async (payload, { rejectWithValue }) => {
  try {
    const queryParams = queryString.parse(window.location.search);
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;
    const response = await axios.delete(`/shopping_carts/${shoppingCartToken}/promotions`, { data: payload });
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const cartInfoSlice = createSlice({
  name: 'cartInfo',
  initialState,
  reducers: {
    updateCartItems: (state, data) => {
      const queryParams = queryString.parse(window.location.search);
      const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
      let cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
      if (cartSessions.some(value => value.eventSession === queryParams?.eventSession)) cartSessions = cartSessions.map(value => value.eventSession === queryParams?.eventSession ? { ...value, token: data.payload.token } : value);
      else cartSessions.push({ eventSession: queryParams?.eventSession, token: data.payload.token });

      localStorage?.setItem("bailaCartSessions", JSON.stringify(cartSessions));
      state.token = data.payload.token;
      state.timeLeft = data?.payload?.timeLeft;
      state.cartItems = data?.payload?.shoppingCartItem;
      state.charges = (Math.round(data?.payload?.totalPriceBreakdown?.charges?.charge?.reduce((acumulator, currentValue) => ({ value: acumulator.value + currentValue.value }), { value: 0 })?.value * 100) / 100).toFixed(2);
      state.promotions = (Math.round((data?.payload?.totalPriceBreakdown?.automaticCartPromotion + data?.payload?.totalPriceBreakdown?.automaticPromotion + data?.payload?.totalPriceBreakdown?.groupCartPromotion + data?.payload?.totalPriceBreakdown?.manualPromotion) * 100) / 100).toFixed(2);
      state.basePrice = (Math.round(data?.payload?.totalPriceBreakdown?.basePrice * 100) / 100).toFixed(2);
      state.finalPrice = (Math.round(data?.payload?.totalPriceBreakdown?.finalPrice * 100) / 100).toFixed(2);
      if (data?.payload?.insurance) {
        state.cartInsurance = [data?.payload?.insurance];
        state.hasInsurance = true;
      };
    },
    removeCartItems: (state, data) => {
      // console.log('REDUX: Cart Remove');
      const queryParams = queryString.parse(window.location.search);
      const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
      const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
      const currentCartSessions = cartSessions.filter(cartSession => cartSession.eventSession !== queryParams?.eventSession);
      localStorage?.setItem("bailaCartSessions", JSON.stringify(currentCartSessions));
      state.token = initialState.token;
      state.timeLeft = initialState.timeLeft;
      state.cartItems = initialState.cartItems;
      state.charges = initialState.charges;
      state.promotions = initialState.promotions;
      state.basePrice = initialState.basePrice;
      state.finalPrice = initialState.finalPrice;
      state.insurance = initialState.insurance;
      state.cartInsurance = initialState.cartInsurance;
      // state.upsellings = initialState.upsellings;
      state.loadingGetUpsellings = initialState.loadingGetUpsellings;
      state.loadingGetInsurance = initialState.loadingGetInsurance;
    },
    setUpsellings: (state, action) => {
      state.cartUpsellings = action.payload;
    },
    updateUpsellings: (state, action) => {
      let cartUpsellings = null;
      const queryParams = queryString.parse(window.location.search);
      const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
      const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
      const found = action.payload.cartUpsellings?.find(cartVariation => cartVariation.id === action.payload.variation.id);

      if (found) {
        if (action.payload.quantity > 0) {
          cartUpsellings = action.payload.cartUpsellings.map(cartVariation => cartVariation.id === action.payload.variation.id ? { ...cartVariation, quantity: cartVariation.quantity + action.payload.quantity } : cartVariation);
          state.cartUpsellings = cartUpsellings;

        } else {
          cartUpsellings = state.cartUpsellings = action.payload.cartUpsellings.filter(cartVariation => cartVariation.id !== action.payload.variation.id);
          state.cartUpsellings = cartUpsellings;
        }

      } else {
        if (action.payload.quantity > 0) {
          cartUpsellings = [...action.payload.cartUpsellings, { ...action.payload.variation, quantity: action.payload.quantity }];
          state.cartUpsellings = cartUpsellings;
        }
      }

      // Save to localStorage
      if (cartUpsellings) {
        const currentCartSessions = cartSessions.map(cartSession => cartSession.eventSession === queryParams?.eventSession ? { ...cartSession, upsellings: cartUpsellings } : cartSession);
        localStorage?.setItem("bailaCartSessions", JSON.stringify(currentCartSessions));
      }
    },
    deleteCartVariation: (state, action) => {
      let cartUpsellings = null;
      const queryParams = queryString.parse(window.location.search);
      const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
      const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
      const found = action.payload.cartUpsellings?.find(cartVariation => cartVariation.id === action.payload.id);

      if (found) {
        cartUpsellings = state.cartUpsellings = action.payload.cartUpsellings.filter(cartVariation => cartVariation.id !== action.payload.id);
        state.cartUpsellings = cartUpsellings;
      }

      // Save to localStorage
      if (cartUpsellings) {
        const currentCartSessions = cartSessions.map(cartSession => cartSession.eventSession === queryParams?.eventSession ? { ...cartSession, upsellings: cartUpsellings } : cartSession);
        localStorage?.setItem("bailaCartSessions", JSON.stringify(currentCartSessions));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShoppingCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShoppingCart.fulfilled, (state, action) => {
        // console.log("REDUX: GET SHOPPING CART => ", action?.payload);
        updateCart(state, action);
      })
      .addCase(getShoppingCart.rejected, (state, action) => {
        // window.location.href = process.env.REACT_APP_FRONT_URL;
        const queryParams = queryString.parse(window.location.search);
        const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
        const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
        const currentCartSessions = cartSessions.filter(cartSession => cartSession.eventSession !== queryParams?.eventSession);
        localStorage?.setItem("bailaCartSessions", JSON.stringify(currentCartSessions));
        state.cartUpsellings = initialState.cartUpsellings;
      })

      .addCase(getUpsellings.pending, (state) => {
        state.loadingGetUpsellings = true;
      })
      .addCase(getUpsellings.fulfilled, (state, action) => {
        state.upsellings = action?.payload;
        state.loadingGetUpsellings = false;
      })
      .addCase(getUpsellings.rejected, (state, action) => {
        state.loadingGetUpsellings = false;
      })

      .addCase(getInsurance.pending, (state) => {
        state.loadingGetInsurance = true;
      })
      .addCase(getInsurance.fulfilled, (state, action) => {
        state.insurance = action?.payload?.insurance;
        if (state.insurance?.insuranceCost) state.cartInsuranceCost = (Math.round(Number(action?.payload?.insurance?.insuranceCost) * 100) / 100).toFixed(2);
        state.loadingGetInsurance = false;
      })
      .addCase(getInsurance.rejected, (state, action) => {
        state.loadingGetInsurance = false;
      })

      .addCase(addInsurance.pending, (state) => {
        state.loadingAddInsurance = true;
      })
      .addCase(addInsurance.fulfilled, (state, action) => {
        state.cartInsurance = [action?.payload?.insurance];
        state.hasInsurance = true;
        state.loadingAddInsurance = false;
      })
      .addCase(addInsurance.rejected, (state, action) => {
        state.loadingAddInsurance = false;
      })

      .addCase(deleteInsurance.pending, (state) => {
        state.loadingDeleteInsurance = true;
      })
      .addCase(deleteInsurance.fulfilled, (state, action) => {
        state.cartInsurance = initialState.cartInsurance;
        state.hasInsurance = false;
        state.loadingDeleteInsurance = false;
      })
      .addCase(deleteInsurance.rejected, (state, action) => {
        state.loadingDeleteInsurance = false;
      })

      .addCase(deleteCartItem.pending, (state) => {
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        updateCart(state, action);
      })
      .addCase(deleteCartItem.rejected, (state, action) => {
      })

      .addCase(addPromotionCode.pending, (state) => {
        state.loadingAddPromotionCode = true;
      })
      .addCase(addPromotionCode.fulfilled, (state, action) => {
        updateCart(state, action);
        state.loadingAddPromotionCode = false;
      })
      .addCase(addPromotionCode.rejected, (state, action) => {
        state.loadingAddPromotionCode = false;
      })

      .addCase(deletePromotionCode.pending, (state) => {
      })
      .addCase(deletePromotionCode.fulfilled, (state, action) => {
        updateCart(state, action);
      })
      .addCase(deletePromotionCode.rejected, (state, action) => {
      })
  },
});

function updateCart(state, action) {
  if (!action?.payload['shopping-cart-item']) {
    state.cartItems = [];

  } else if (Array.isArray(action?.payload['shopping-cart-item'])) {
    state.cartItems = action?.payload['shopping-cart-item'].map(cartItem => cartItemParse(cartItem));

    // If single comes as object
  } else {
    state.cartItems = [cartItemParse(action?.payload['shopping-cart-item'])];
  }

  state.cartPromotions = action?.payload?.cartPromotions?.groupCartPromotion ? action?.payload?.cartPromotions?.groupCartPromotion['@id'] : '';
  state.timeLeft = action?.payload['@timeLeft'];
  state.charges = (Math.round(action?.payload['total-price-breakdown']?.charges?.charge?.reduce((acumulator, currentValue) => ({ value: Number(acumulator.value) + Number(currentValue.value) }), { value: 0 })?.value * 100) / 100).toFixed(2);
  state.promotions = (Math.round((Number(action?.payload['total-price-breakdown']['automatic-cart-promotion']) + Number(action?.payload['total-price-breakdown']['automatic-promotion']) + Number(action?.payload['total-price-breakdown']['group-cart-promotion']) + Number(action?.payload['total-price-breakdown']['manual-promotion']) + Number(action?.payload['total-price-breakdown']['manual-discount'])) * 100) / 100).toFixed(2);
  state.basePrice = (Math.round(Number(action?.payload['total-price-breakdown']['base-price']) * 100) / 100).toFixed(2);
  state.finalPrice = (Math.round(Number(action?.payload['total-price-breakdown']['final-price']) * 100) / 100).toFixed(2);
  if (action?.payload?.insurance) {
    state.cartInsurance = [action?.payload?.insurance];
    state.hasInsurance = true;
  };
}

function cartItemParse(item) {
  let cartItem = {};
  cartItem.id = item['@id'];
  cartItem.ticketData = item['ticket-data'];
  cartItem.ticketData.ticketType = cartItem.ticketData['@ticketType'];
  cartItem.priceBreakdown = {};
  cartItem.priceBreakdown.basePrice = item['price-breakdown']['base-price'];
  return cartItem;
}

export const { updateCartItems, removeCartItems, setUpsellings, updateUpsellings, deleteCartVariation } = cartInfoSlice.actions;
export const selectToken = (state) => state.cartInfo.token;
export const selectTimeLeft = (state) => state.cartInfo.timeLeft;
export const selectCartItems = (state) => state.cartInfo.cartItems;
export const selectPromotions = (state) => state.cartInfo.promotions;
export const selectCartPromotions = (state) => state.cartInfo.cartPromotions;
export const selectCharges = (state) => state.cartInfo.charges;
export const selectBasePrice = (state) => state.cartInfo.basePrice;
export const selectFinalPrice = (state) => state.cartInfo.finalPrice;
export const selectInsurance = (state) => state.cartInfo.insurance;
export const selectLoadingGetInsurance = (state) => state.cartInfo.loadingGetInsurance;
export const selectLoadingAddPromotionCode = (state) => state.cartInfo.loadingAddPromotionCode;
export const selectLoadingDeleteInsurance = (state) => state.cartInfo.loadingDeleteInsurance;
export const selectHasInsurance = (state) => state.cartInfo.hasInsurance;
export const selectCartInsurance = (state) => state.cartInfo.cartInsurance;
export const selectCartInsuranceCost = (state) => state.cartInfo.cartInsuranceCost;
export const selectUpsellings = (state) => state.cartInfo.upsellings;
export const selectCartUpsellings = (state) => state.cartInfo.cartUpsellings;
export const selectLoadingUpsellings = (state) => state.cartInfo.loadingUpsellings;
export default cartInfoSlice.reducer;
