import React from 'react';
import { Offcanvas as OffCanvas } from 'react-bootstrap';
import EventInfoCard from '../event-info-card/event-info-card';
import styles from './event-info-offcanvas.module.scss';

export default function EventInfoOffcanvas() {
  const [show, setShow] = React.useState(true);

  return (
    <>
      <OffCanvas show={show} onHide={() => setShow(false)} placement={'bottom'} name={"bottom"} className={styles['offcanvas-bottom']}>
        <OffCanvas.Body className={styles['offcanvas-body'] + " rounded"}>
          <EventInfoCard showOffcanvas={show} setShowOffcanvas={setShow} />
        </OffCanvas.Body>
      </OffCanvas>
    </>
  );
}
