import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, FloatingLabel, Button, Spinner, InputGroup, Navbar } from 'react-bootstrap';
import { Formik, ErrorMessage } from "formik";
import { useSelector } from 'react-redux';
import { getCountryCodes, selectCountryCodes, selectLoading } from '../auth.slice';
import { register } from '../auth.slice';
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../../cart-info/cart-info.slice';
import { toggle } from '../../../parts/offcanvas/offcanvas.slice';
import { showAlert } from '../../alert/alert.slice';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

export default function Register() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectLoading);
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const countryCodes = useSelector(selectCountryCodes);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const queryParams = queryString.parse(history.location.search);
  const cartUpsellings = useSelector(selectCartUpsellings);

  React.useEffect(() => {
    dispatch(getCountryCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    repeat_email: Yup.string().email().required().oneOf([Yup.ref('email')], t("VALIDATION.EMAIL_DO_NOT_MATCH")),
    password: Yup.string().required(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    phone_number_country_code: Yup.string().required(),
    phone_number: Yup.string().required(),
    zip_code: Yup.string().required(),
    terms: Yup.bool().required().oneOf([true], t("VALIDATION.TERMS")),
  });

  const initialValues = {
    email: "",
    repeat_email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone_number_country_code: "+34",
    phone_number: "",
    zip_code: "",
    event_session_id: queryParams?.eventSession,
    terms: false,
  };

  const onSubmit = async (values, { setErrors, resetForm }) => {
    dispatch(register(values)).then((response) => {
      if (response?.error) {
        setErrors(response?.payload);
        dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.FORM_INVALID") }));

      } else {
        history.push("/account" + history.location.search);
      }
    });
  };

  const renderError = (message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Row>
              <Col md={6}>
                <FloatingLabel controlId="email" label={t("REGISTER.FORM.INPUT.EMAIL")} className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder={t("REGISTER.FORM.INPUT.EMAIL")}
                    value={values.email}
                    isInvalid={touched?.email && !!errors?.email}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="email" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId="repeat_email" label={t("REGISTER.FORM.INPUT.REPEAT_EMAIL")} className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder={t("REGISTER.FORM.INPUT.REPEAT_EMAIL")}
                    value={values.repeat_email}
                    isInvalid={touched?.repeat_email && !!errors?.repeat_email}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="repeat_email" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId="password" label={t("REGISTER.FORM.INPUT.PASSWORD")} className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder={t("REGISTER.FORM.INPUT.PASSWORD")}
                    value={values.password}
                    isInvalid={touched?.password && !!errors?.password}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="password" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId="zip_code" label={t("REGISTER.FORM.INPUT.ZIP_CODE")} className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={t("REGISTER.FORM.INPUT.ZIP_CODE")}
                    value={values.zip_code}
                    isInvalid={touched?.zip_code && !!errors?.zip_code}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="zip_code" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId="first_name" label={t("REGISTER.FORM.INPUT.FIRST_NAME")} className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={t("REGISTER.FORM.INPUT.FIRST_NAME")}
                    value={values.first_name}
                    isInvalid={touched?.first_name && !!errors?.first_name}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="first_name" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId="last_name" label={t("REGISTER.FORM.INPUT.LAST_NAME")} className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={t("REGISTER.FORM.INPUT.LAST_NAME")}
                    value={values.last_name}
                    isInvalid={touched?.last_name && !!errors?.last_name}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="last_name" render={renderError} />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <InputGroup hasValidation className="mb-3 d-flex flex-nowrap">
                  <FloatingLabel controlId="phone_number_country_code" label={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER_COUNTRY_CODE")} style={{ minWidth: '95px', maxWidth: '95px' }}>
                    <Form.Select value={values?.phone_number_country_code} onChange={handleChange} aria-label={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER_COUNTRY_CODE")} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                      {countryCodes.map((countryCode, i) => <option key={i} value={countryCode?.id}>{countryCode?.id}</option>)}
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel controlId="phone_number" label={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER")} className="flex-fill">
                    <Form.Control
                      type="tel"
                      placeholder={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER")}
                      style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                      value={values?.phone_number}
                      isInvalid={touched?.phone_number && !!errors?.phone_number}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="phone_number" render={(message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>} />
                  </FloatingLabel>
                </InputGroup>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Check
                id="terms"
                type="checkbox"
                name="terms"
                label={<span>{t("REGISTER.FORM.INPUT.ACCEPT")} <a href={'https://baila.fm/privacy.html'} target="_blank" rel="noreferrer">{t("REGISTER.FORM.INPUT.PRIVACY_POLICY")}</a> {t("GLOBAL.AND")} <a href={'https://baila.fm/terms.html'} target="_blank" rel="noreferrer">{t("REGISTER.FORM.INPUT.PURCHASE_CONDITIONS")}</a></span>}
                checked={values.terms}
                onChange={handleChange}
                isInvalid={touched?.terms && !!errors?.terms}
                feedback={errors?.terms}
              />
            </Form.Group>

            <Button variant="dark" size="lg" type="submit" className="d-none d-lg-block" disabled={loading}>
              {loading ?
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                :
                <>{t("REGISTER.FORM.BUTTON.SUBMIT")}</>
              }
            </Button>

            <Row>
              <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
                {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
                <Button variant="dark" size="lg" type="submit" className="shadow flex-fill" disabled={loading}>
                  {loading ?
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    :
                    <>{t("REGISTER.FORM.BUTTON.SUBMIT")}</>
                  }
                </Button>
              </Navbar>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
