import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingDownloadTickets: false,
  orderError: '',
};

// payload: shopping_cart_token, event_session, pay_tpv_token
export const createOrder = createAsyncThunk('order/createOrder', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/orders', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const confirmOrder = createAsyncThunk('order/confirmOrder', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/orders/confirm', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const getDownloadTickets = createAsyncThunk('order/downloadTickets', async (orderId, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/orders/ticket_download/${orderId}`);
    return { ...response.data, status: response.status };

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setLoadingDownloadTickets: (state, data) => {
      state.loadingDownloadTickets = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        // console.log("REDUX: CREATE ORDER => ", action?.payload);
        state.orderError = initialState.orderError;
        state.loading = false;
      })
      .addCase(createOrder.rejected, (state, action) => {
        if (Array.isArray(action?.payload)) state.orderError = action?.payload?.join(', ');
        state.loading = false;
      })

      .addCase(confirmOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmOrder.fulfilled, (state, action) => {
        // console.log("REDUX: CONFIRM ORDER => ", action?.payload);
        state.orderError = initialState.orderError;
        state.loading = false;
      })
      .addCase(confirmOrder.rejected, (state, action) => {
        if (Array.isArray(action?.payload)) state.orderError = action?.payload?.join(', ');
        state.loading = false;
      })

      .addCase(getDownloadTickets.pending, (state) => {
        // state.loadingDownloadTickets = true;
      })
      .addCase(getDownloadTickets.fulfilled, (state, action) => {
        // console.log("REDUX: DOWNLOAD TICKETS => ", action?.payload);
        // state.loadingDownloadTickets = false;
      })
      .addCase(getDownloadTickets.rejected, (state, action) => {
        // state.loadingDownloadTickets = false;
      })
  },
});

export const { setLoadingDownloadTickets } = orderSlice.actions;
export const selectLoading = (state) => state.order.loading;
export const selectLoadingDownloadTickets = (state) => state.order.loadingDownloadTickets;
export const selectOrderError = (state) => state.order.orderError;
export default orderSlice.reducer;
