import React from "react";
import { FieldArray, Formik, getIn, ErrorMessage } from "formik";
import { Form, Button, Row, Col, FloatingLabel, Spinner, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectCartItems } from "../cart-info/cart-info.slice";
import styles from './attendant.module.scss';
import { addAttendants, getAttendantsDetail, selectAttendants, selectLoadingGet, selectLoadingAdd } from "./attendant.slice";
import * as Yup from "yup";
import { showAlert } from "../alert/alert.slice";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import queryString from 'query-string';

export default function Attendant() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const cartItems = useSelector(selectCartItems);
  const attendants = useSelector(selectAttendants);
  const queryParams = queryString.parse(history.location.search);
  const cartSessions = JSON.parse(localStorage?.getItem('bailaCartSessions')) || [];
  const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
  const shoppingCartToken = currentCartSession?.token;
  const loadingGet = useSelector(selectLoadingGet);
  const loadingAdd = useSelector(selectLoadingAdd);
  const attendantsNumberArray = (cartItems?.length - 1 > 0) ? Array(cartItems?.length - 1)?.fill(0)?.map((_, i) => i) : [];
  const initialValues = {
    attendant: attendantsNumberArray.map((_, i) => {
      if (attendants[i]) return attendants[i];
      return { first_name: "", last_name: "", email: "", zip_code: "" };
    })
  };
  const validationSchema = Yup.object().shape({
    attendant: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string(),
        last_name: Yup.string(),
        email: Yup.string().email(),
        zip_code: Yup.number(),
      })
    )
  });

  React.useEffect(() => {
    dispatch(getAttendantsDetail(shoppingCartToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values, { setErrors, resetForm }) => {
    const payload = {
      shopping_cart_token: shoppingCartToken,
      attendants: values?.attendant?.filter(att => att?.first_name && att?.last_name && att?.email && att?.zip_code),
    }
    dispatch(addAttendants(payload)).then((response) => {
      if (response?.error) {
        setErrors(response.payload);
        dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.FORM_INVALID") }));

      } else {
        dispatch(showAlert({ variant: 'success', message: t("ALERT.SUCCESS.CHANGES_SAVED") }));
      }
    });
  };

  const renderError = (message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>;

  return (
    <div className="mt-5">

      {loadingGet ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> :
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, touched, errors, handleChange, handleBlur, isValid }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {values?.attendant?.length ? <hr /> : null}
                <FieldArray name="attendant">
                  {({ push, remove }) => (
                    <>
                      <Accordion defaultActiveKey={0} flush className={styles['accordion']}>
                        {values?.attendant?.length ? values?.attendant?.map((p, i) => {
                          const first_name = `attendant[${i}].first_name`;
                          const last_name = `attendant[${i}].last_name`;
                          const email = `attendant[${i}].email`;
                          const zip_code = `attendant[${i}].zip_code`;

                          return (
                            <Accordion.Item key={i} eventKey={i} className={styles['accordion-item']}>
                              <Accordion.Button className={styles['accordion-button']}>{t("ATTENDANT.FORM.HEADER.ATTENDANT")} {i + 1} {t("ATTENDANT.FORM.HEADER.OPTIONAL")}</Accordion.Button>
                              <Accordion.Body className={styles['accordion-body']}>
                                <Row>
                                  <Col md={6}>
                                    <FloatingLabel controlId={first_name} label={t("ATTENDANT.FORM.INPUT.FIRST_NAME")} className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder={t("ATTENDANT.FORM.INPUT.FIRST_NAME")}
                                        value={p?.first_name}
                                        isInvalid={getIn(touched, first_name) && getIn(errors, first_name)}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name={first_name} render={renderError} />
                                    </FloatingLabel>
                                  </Col>

                                  <Col md={6}>
                                    <FloatingLabel controlId={last_name} label={t("ATTENDANT.FORM.INPUT.LAST_NAME")} className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder={t("ATTENDANT.FORM.INPUT.LAST_NAME")}
                                        value={p?.last_name}
                                        isInvalid={getIn(touched, last_name) && getIn(errors, last_name)}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name={last_name} render={renderError} />
                                    </FloatingLabel>
                                  </Col>

                                  <Col md={6}>
                                    <FloatingLabel controlId={email} label={t("ATTENDANT.FORM.INPUT.EMAIL")} className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder={t("ATTENDANT.FORM.INPUT.EMAIL")}
                                        value={p?.email}
                                        isInvalid={getIn(touched, email) && getIn(errors, email)}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name={email} render={renderError} />
                                    </FloatingLabel>
                                  </Col>

                                  <Col md={6}>
                                    <FloatingLabel controlId={zip_code} label={t("ATTENDANT.FORM.INPUT.ZIP_CODE")} className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder={t("ATTENDANT.FORM.INPUT.ZIP_CODE")}
                                        value={p?.zip_code}
                                        isInvalid={getIn(touched, zip_code) && getIn(errors, zip_code)}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name={zip_code} render={renderError} />
                                    </FloatingLabel>
                                  </Col>
                                </Row>
                                {/* <Button type="button" variant="link" className="float-start small text-dark text-decoration-none" onClick={() => push({ first_name: "", last_name: "", email: "", zip_code: "" })}>Añadir</Button> */}
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })
                          : null
                        }
                      </Accordion>
                    </>
                  )}
                </FieldArray>

                {attendantsNumberArray?.length ?
                  <Button type="submit" variant="link" className="float-end small text-dark text-decoration-none" disabled={loadingAdd}>
                    {loadingAdd ?
                      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      :
                      <>{t("ATTENDANT.FORM.BUTTON.SUBMIT")}</>
                    }
                  </Button>
                  : null
                }
              </Form>
            )}
          </Formik>
        </>
      }
    </div>
  );
};
