import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import logo from '../../../../assets/images/baila_logo_color.png';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  // col-xl-8 col-md-10 offset-xl-2 offset-md-1
  return (
    <>
      <footer className="footer text-black-50 small d-none d-lg-flex bg-white pt-2 pb-2" style={{ position: 'fixed', bottom: 0, width: 'inherit', left: 0 }}>
        <div className="container-fluid">
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 9, offset: 2 }}>
              <div className="d-flex">
                <a href="https://baila.fm">
                  <Image src={logo} width="75" className="" />
                </a>
                {/* <Row className="mt-3">
                <Col xs={{ span: 4, offset: 4 }}>
                  <Form.Select size="sm">
                    <option>{t("LANGUAGE.ES")}</option>
                    <option>{t("LANGUAGE.EN")}</option>
                    <option>{t("LANGUAGE.CA")}</option>
                  </Form.Select>
                </Col>
              </Row> */}
                <div style={{ display: 'flex', flex: 1 }}></div>
                <div className="d-flex align-items-end text-end">
                  <div>
                    <a href="https://baila.fm/terms.html" target="_blank" rel="noreferrer" className="text-muted text-decoration-none">{t("FOOTER.BUTTON.TERMS")}</a> | <a href="https://baila.fm/privacy.html" target="_blank" rel="noreferrer" className="text-muted text-decoration-none">{t("FOOTER.BUTTON.PRIVACY")}</a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
}
