import React from 'react';
import { Form, Row, Col, Button, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { setProgress } from '../../checkout.slice';
import { toggle } from '../../parts/offcanvas/offcanvas.slice';
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../cart-info/cart-info.slice';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from "react-icons/bs";

export default function Payment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const queryParams = queryString.parse(history.location.search);
  const eventSession = queryParams?.eventSession;
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartUpsellings = useSelector(selectCartUpsellings);

  React.useEffect(() => {
    dispatch(setProgress(80));
  }, [dispatch]);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=es";
    script.async = true;
    document.body.append(script);
  }, []);

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <Link className="float-start cursor-pointer text-dark text-decoration-none" to={"/services" + history.location.search}><BsChevronLeft className="text-dark" /></Link>
        <h6 className="pe-2">{t("PAYMENT.TITLE")}</h6>
      </div>

      <>
        {/* <form id="paycometPaymentForm" action="/order" method="GET">
          <input type="hidden" name="eventSession" value="1" />
          <input type="hidden" name="amount" value="250" />
          <input type="hidden" data-paycomet="jetID" value="QMBcR2sneITxk4A1HFXSfJg7b6mZKj3r" />

          <Row>
            <Col md={6}>
              <FloatingLabel controlId="username" label="Titular de la tarjeta" className="mb-3">
                <Form.Control type="text" data-paycomet="cardHolderName" placeholder="Titular de la tarjeta" required />
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FloatingLabel controlId="pan" label="Número de la tarjeta" className="mb-3">
                <Form.Control type="text" paycomet-name="pan" placeholder="Número de la tarjeta" required />
              </FloatingLabel>
            </Col>
          </Row>

          <button className="subscribe btn btn-primary btn-block" type="submit">Confirmar</button>
        </form>
        <div id="paymentErrorMsg" className="text-danger"></div> */}


        <Row>
          <div className="card-body">
            <Form id="paycometPaymentForm" action="/order" method="GET">
              <input type="hidden" name="eventSession" value={eventSession} />
              <input type="hidden" name="amount" value="250" />
              <input type="hidden" data-paycomet="jetID" value={process?.env?.REACT_APP_PAYCOMET_JET_ID} />

              <Row>
                <Col md="6" className="mt-3">
                  <div className="form-group">
                    <label>{t("PAYMENT.FORM.INPUT.CARD_HOLDER")}</label>
                    <div className="input-group">
                      <input type="text" className="form-control" name="username" data-paycomet="cardHolderName" placeholder="" required="" />
                    </div>
                  </div>
                </Col>

                <Col md="6" className="mt-3">
                  <div className="form-group">
                    <label>{t("PAYMENT.FORM.INPUT.CARD_NUMBER")}</label>
                    <div id="paycomet-pan" style={{ padding: "0px", height: "36px" }}></div>
                    <input paycomet-style="width: -webkit-fill-available; height: 21px; font-size: 14px; padding-left: 7px; padding-top: 8px; display: block; padding: 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; -webkit-appearance: none; appearance: none; border-radius: 0.25rem; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;" paycomet-name="pan" />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Row>
                    <Col md="6" className="mt-3">
                      <div className="form-group">
                        <label>{t("PAYMENT.FORM.INPUT.MONTH")}</label>
                        <select className="form-control" data-paycomet="dateMonth">
                          <option></option>
                          <option value="01">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.JAN")}</option>
                          <option value="02">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.FEB")}</option>
                          <option value="03">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.MAR")}</option>
                          <option value="04">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.APR")}</option>
                          <option value="05">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.MAY")}</option>
                          <option value="06">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.JUN")}</option>
                          <option value="07">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.JUL")}</option>
                          <option value="08">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.AUG")}</option>
                          <option value="09">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.SEP")}</option>
                          <option value="10">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.OCT")}</option>
                          <option value="11">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.NOV")}</option>
                          <option value="12">{t("PAYMENT.FORM.INPUT.MONTH_OPTIONS.DEC")}</option>
                        </select>
                      </div>
                    </Col>
                    <Col md="6" className="mt-3">
                      <div className="form-group">
                        <label>{t("PAYMENT.FORM.INPUT.YEAR")}</label>
                        <select className="form-control" data-paycomet="dateYear">
                          <option></option>
                          <option value="20">2020</option>
                          <option value="21">2021</option>
                          <option value="22">2022</option>
                          <option value="23">2023</option>
                          <option value="24">2024</option>
                          <option value="25">2025</option>
                          <option value="26">2026</option>
                          <option value="27">2027</option>
                          <option value="28">2028</option>
                          <option value="29">2029</option>
                          <option value="30">2030</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md="6" className="mt-3">
                  <div className="form-group">
                    <label>{t("PAYMENT.FORM.INPUT.CVV")}</label>
                    <div id="paycomet-cvc2" style={{ height: "36px", padding: "0px" }}></div>
                    <input paycomet-name="cvc2" paycomet-style="width: -webkit-fill-available; height: 21px; font-size: 14px; padding-left: 7px; padding-top: 8px; display: block; padding: 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; -webkit-appearance: none; appearance: none; border-radius: 0.25rem; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;" className="form-control" required="" type="text" />
                  </div>
                </Col>

              </Row>
              <div id="paymentErrorMsg" className="text-danger mt-3"></div>
              <Button type="submit" variant="dark" size="lg" className="subscribe shadow mt-3 d-none d-lg-block">{t("PAYMENT.FORM.BUTTON.SUBMIT")}</Button>

              <Row>
                <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
                  {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
                  <Button type="submit" variant="dark" size="lg" className="subscribe shadow flex-fill">{t("PAYMENT.FORM.BUTTON.SUBMIT")}</Button>
                </Navbar>
              </Row>

            </Form>
          </div>
        </Row>
      </>
    </>
  );
}
