import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingAdd: false,
  loadingGet: true,
  attendants: [],
};

// payload -> shopping_cart_token, attendants
export const addAttendants = createAsyncThunk('attendant/addAttendants', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/orders/attendants', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const getAttendantsDetail = createAsyncThunk('attendant/getAttendantsDetail', async (shoppingCartToken, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/orders/attendants/${shoppingCartToken}`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const attendantSlice = createSlice({
  name: 'attendant',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendantsDetail.pending, (state) => {
        state.loadingGet = true;
      })
      .addCase(getAttendantsDetail.fulfilled, (state, action) => {
        // console.log('REDUX: Get Attendants Detail ->', action?.payload)
        state.attendants = action?.payload;
        state.loadingGet = false;
      })
      .addCase(getAttendantsDetail.rejected, (state, action) => {
        state.loadingGet = false;
      })
      .addCase(addAttendants.pending, (state) => {
        state.loadingAdd = true;
      })
      .addCase(addAttendants.fulfilled, (state, action) => {
        state.attendants = action?.payload;
        state.loadingAdd = false;
      })
      .addCase(addAttendants.rejected, (state, action) => {
        state.loadingAdd = false;
      })
  },
});

export const { update, remove } = attendantSlice.actions;
export const selectLoadingGet = (state) => state.attendant.loadingGet;
export const selectLoadingAdd = (state) => state.attendant.loadingAdd;
export const selectAttendants = (state) => state.attendant.attendants;
export default attendantSlice.reducer;
