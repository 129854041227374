import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setTokenLocalStorage, setUserLocalStorage } from '../../../../services/auth';

const initialState = {
  user: {},
  countryCodes: [],
  loading: false,
  loadingGetEmailVerification: false,
  loadingEmailVerification: false,
  loadingCountryCodes: false,
};

export const login = createAsyncThunk('auth/login', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/login', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const register = createAsyncThunk('auth/register', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const update = createAsyncThunk('auth/update', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.patch('/users', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const getPasswordRecovery = createAsyncThunk('auth/getPasswordRecovery', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/users/password_recovery?email=${payload.email}&event_session_id=${payload.eventSession}`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> email, token, password
export const passwordRecovery = createAsyncThunk('auth/passwordRecovery', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/password_recovery', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const getEmailVerification = createAsyncThunk('auth/getEmailVerification', async (eventSession, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/users/email_verification?event_session_id=${eventSession}`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

// payload -> token
export const emailVerification = createAsyncThunk('auth/emailVerification', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/email_verification', payload);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const getCountryCodes = createAsyncThunk('auth/getCountryCodes', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/phone_number_country_codes`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, data) => {
      state.user = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        setTokenLocalStorage(action?.payload?.auth_token?.key);
        setUserLocalStorage(action?.payload);
        state.user = action?.payload;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        setTokenLocalStorage(action?.payload?.auth_token?.key);
        setUserLocalStorage(action?.payload);
        state.user = action?.payload;
        state.loading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(update.pending, (state) => {
        state.loading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        setTokenLocalStorage(action?.payload?.auth_token?.key);
        setUserLocalStorage(action?.payload);
        state.user = action?.payload;
        state.loading = false;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPasswordRecovery.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPasswordRecovery.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getPasswordRecovery.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(passwordRecovery.pending, (state) => {
        state.loading = true;
      })
      .addCase(passwordRecovery.fulfilled, (state, action) => {
        setTokenLocalStorage(action?.payload?.auth_token?.key);
        setUserLocalStorage(action?.payload);
        state.user = action?.payload;
        state.loading = false;
      })
      .addCase(passwordRecovery.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getEmailVerification.pending, (state) => {
        state.loadingGetEmailVerification = true;
      })
      .addCase(getEmailVerification.fulfilled, (state, action) => {
        state.loadingGetEmailVerification = false;
      })
      .addCase(getEmailVerification.rejected, (state, action) => {
        state.loadingGetEmailVerification = false;
      })

      .addCase(emailVerification.pending, (state) => {
        state.loadingEmailVerification = true;
      })
      .addCase(emailVerification.fulfilled, (state, action) => {
        // console.log("REDUX: email Verification -> ", action?.payload)
        setTokenLocalStorage(action?.payload?.auth_token?.key);
        setUserLocalStorage(action?.payload);
        state.user = action?.payload;
        state.loadingEmailVerification = false;
      })
      .addCase(emailVerification.rejected, (state, action) => {
        state.loadingEmailVerification = false;
      })

      .addCase(getCountryCodes.pending, (state) => {
        state.loadingCountryCodes = true;
      })
      .addCase(getCountryCodes.fulfilled, (state, action) => {
        state.countryCodes = action.payload;
        state.loadingCountryCodes = false;
      })
      .addCase(getCountryCodes.rejected, (state, action) => {
        state.countryCodes = action.payload;
        state.loadingCountryCodes = false;
      })
  },
});

export const { setUser } = authSlice.actions;
export const selectLoading = (state) => state.auth.loading;
export const selectLoadingGetEmailVerification = (state) => state.auth.loadingGetEmailVerification;
export const selectLoadingEmailVerification = (state) => state.auth.loadingEmailVerification;
export const selectLoadingCountryCodes = (state) => state.auth.loadingCountryCodes;
export const selectCountryCodes = (state) => state.auth.countryCodes;
export const selectUser = (state) => state.auth.user;
export default authSlice.reducer;
