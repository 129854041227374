import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectShow, close, toggle } from './offcanvas.slice';
import { Offcanvas as OffCanvas, Row, Navbar, Button } from 'react-bootstrap';
import CartInfo from '../../components/cart-info/cart-info';
import styles from './offcanvas.module.scss';
import { Switch, Route, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Offcanvas() {
  const { t } = useTranslation();
  const history = useHistory();
  const show = useSelector(selectShow);
  const dispatch = useDispatch();

  return (
    <>
      <OffCanvas show={show} onHide={() => dispatch(close())} placement={'bottom'} name={"bottom"} className={styles['offcanvas-bottom']}>
        <OffCanvas.Header className="justify-content-center">
          <OffCanvas.Title className="fw-bold">{t("OFFCANVAS.HEADER.TITLE")}</OffCanvas.Title>
        </OffCanvas.Header>
        <OffCanvas.Body className={styles['offcanvas-body']}>
          <CartInfo headerClasses="text-center" />

          <div className="d-grid gap-2">
            <Switch>
              <Route exact path="/payment">
                <Button variant="light" size="lg" className="fw-bold" onClick={() => { dispatch(toggle()); history.push("/account" + history.location.search) }}>{t("OFFCANVAS.BUTTON.EDIT_DATA")}</Button>
                <Button variant="light" size="lg" className="fw-bold" onClick={() => { dispatch(toggle()); history.push("/" + history.location.search) }}>{t("OFFCANVAS.BUTTON.EDIT_CART")}</Button>
              </Route>
              <Route exact path="/login">
                <Button variant="light" size="lg" className="fw-bold" onClick={() => { dispatch(toggle()); history.push("/" + history.location.search) }}>{t("OFFCANVAS.BUTTON.EDIT_CART")}</Button>
              </Route>
              <Route>
                <Button variant="light" size="lg" className="fw-bold" onClick={() => { dispatch(toggle()); history.push("/" + history.location.search) }}>{t("OFFCANVAS.BUTTON.EDIT_CART")}</Button>
              </Route>
            </Switch>
          </div>

        </OffCanvas.Body>

        <Row>
          <Navbar fixed="bottom" bg="light" className="shadow">
            <Button variant="light" size="lg" className="shadow flex-fill" onClick={() => dispatch(toggle())}>{t("OFFCANVAS.BUTTON.BACK")}</Button>
          </Navbar>
        </Row>
      </OffCanvas>
    </>
  );
}
