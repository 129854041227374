import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, FloatingLabel, Button, Spinner, InputGroup, Navbar } from 'react-bootstrap';
import { Formik, ErrorMessage } from "formik";
import { useSelector } from 'react-redux';
import { selectLoading, selectUser, update, getCountryCodes, selectCountryCodes } from '../auth/auth.slice';
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { selectEventInfo, setProgress } from '../../checkout.slice';
import Attendant from '../attendant/attendant';
import { toggle } from '../../parts/offcanvas/offcanvas.slice';
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../cart-info/cart-info.slice';
import { showAlert } from '../alert/alert.slice';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from "react-icons/bs";
// import queryString from 'query-string';

export default function Payment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectLoading);
  // const queryParams = queryString.parse(history.location.search);
  // const loadingGetEmailVerification = useSelector(selectLoadingGetEmailVerification);
  // const loadingEmailVerification = useSelector(selectLoadingEmailVerification);
  // const [verifying, setVerifying] = React.useState(false);
  const user = useSelector(selectUser);
  const initialValues = user;
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const countryCodes = useSelector(selectCountryCodes);
  const eventInfo = useSelector(selectEventInfo);
  const [consentedPromoters, setConsentedPromoters] = React.useState(user?.consented_promoters || []);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartUpsellings = useSelector(selectCartUpsellings);

  React.useEffect(() => {
    dispatch(setProgress(48));
    dispatch(getCountryCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   if (!user?.email_verified && queryParams?.action === 'verify_email' && queryParams?.token) {
  //     const payload = {
  //       token: queryParams?.token
  //     }
  //     dispatch(emailVerification(payload)).then((response) => {
  //       if (response?.error) {
  //         dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.ACCOUNT_UNVERIFIED") }));

  //       } else {
  //         dispatch(showAlert({ variant: 'success', message: t("ALERT.SUCCESS.ACCOUNT_VERIFIED") }));
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    phone_number_country_code: Yup.string().required(),
    phone_number: Yup.string().required(),
    zip_code: Yup.string().required(),
  });

  const onSubmit = async (values, { setErrors, resetForm }) => {
    let payload = { ...values };
    if (!values?.password) delete payload.password;
    if (consentedPromoters) payload['consented_promoters'] = consentedPromoters;

    dispatch(update(payload)).then((response) => {
      if (response?.error) {
        dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.FORM_INVALID") }));
        setErrors(response?.payload);
      }
      else {
        dispatch(showAlert({ variant: 'success', message: t("ALERT.SUCCESS.CHANGES_SAVED") }));
      }
    });
  };

  // function sendEmailVerification() {
  //   dispatch(getEmailVerification(queryParams?.eventSession)).then((response) => {
  //     if (response?.error) {
  //       dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.EMAIL_VERIFICATION") }));

  //     } else {
  //       setVerifying(true);
  //       dispatch(showAlert({ variant: 'success', message: t("ALERT.SUCCESS.EMAIL_VERIFICATION") }));
  //     }
  //   });
  // }

  const renderError = (message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>;

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <Link className="float-start cursor-pointer text-dark text-decoration-none" to={!user ? "/login" + history.location.search : "/" + history.location.search}><BsChevronLeft className="text-dark" /></Link>
        <h6 className="pe-2">{t("ACCOUNT.TITLE")}</h6>
      </div>

      <Link to={"account" + history.location.search} className={"text-dark text-decoration-none fw-bold"}>{t("ACCOUNT.TABS.YOUR_INFO")}</Link>
      <Link to={"login" + history.location.search} className={"text-dark text-decoration-none text-black-50 float-end"}>{t("ACCOUNT.TABS.CHANGE_ACCOUNT")}</Link>

      <hr />

      <>
        <Formik
          initialValues={{ password: '', ...initialValues }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
            >
              <Row>
                <Col md={6}>
                  <FloatingLabel controlId="email" label={t("ACCOUNT.FORM.INPUT.EMAIL")} className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder={t("ACCOUNT.FORM.INPUT.EMAIL")}
                      value={values?.email}
                      isInvalid={touched?.email && !!errors?.email}
                      onChange={handleChange}
                      disabled
                    />
                    <ErrorMessage name="email" render={renderError} />
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  <FloatingLabel controlId="password" label={t("ACCOUNT.FORM.INPUT.PASSWORD")} className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder={t("ACCOUNT.FORM.INPUT.PASSWORD")}
                      value={values?.password}
                      isInvalid={touched?.password && !!errors?.password}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="password" render={renderError} />
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  <FloatingLabel controlId="first_name" label={t("ACCOUNT.FORM.INPUT.FIRST_NAME")} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder={t("ACCOUNT.FORM.INPUT.FIRST_NAME")}
                      value={values?.first_name}
                      isInvalid={touched?.first_name && !!errors?.first_name}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="first_name" render={renderError} />
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  <FloatingLabel controlId="last_name" label={t("ACCOUNT.FORM.INPUT.LAST_NAME")} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder={t("ACCOUNT.FORM.INPUT.LAST_NAME")}
                      value={values?.last_name}
                      isInvalid={touched?.last_name && !!errors?.last_name}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="last_name" render={renderError} />
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  <InputGroup hasValidation className="mb-3 d-flex flex-nowrap">
                    <FloatingLabel controlId="phone_number_country_code" label={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER_COUNTRY_CODE")} style={{ minWidth: '95px', maxWidth: '95px' }}>
                      <Form.Select value={values?.phone_number_country_code} onChange={handleChange} aria-label={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER_COUNTRY_CODE")} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                        {countryCodes?.map((countryCode, i) => <option key={i} value={countryCode?.id}>{countryCode?.id}</option>)}
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId="phone_number" label={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER")} className="flex-fill">
                      <Form.Control
                        type="tel"
                        placeholder={t("ACCOUNT.FORM.INPUT.PHONE_NUMBER")}
                        style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                        value={values?.phone_number}
                        isInvalid={touched?.phone_number && !!errors?.phone_number}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="phone_number" render={(message) => <Form.Control.Feedback type="invalid">{[message]?.join(' ')}</Form.Control.Feedback>} />
                    </FloatingLabel>
                  </InputGroup>
                </Col>

                <Col md={6}>
                  <FloatingLabel controlId="zip_code" label={t("ACCOUNT.FORM.INPUT.ZIP_CODE")} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder={t("ACCOUNT.FORM.INPUT.ZIP_CODE")}
                      value={values?.zip_code}
                      isInvalid={touched?.zip_code && !!errors?.zip_code}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="zip_code" render={renderError} />
                  </FloatingLabel>
                </Col>

                {!eventInfo?.promoters?.length ? null : eventInfo?.promoters?.map((promoter, i) => {
                  return promoter.is_active &&
                    <Col xs={12} key={i}>
                      <Form.Group className="mb-3" controlId={`promoter${i}`}>
                        <Form.Check
                          type="checkbox"
                          label={t("ACCOUNT.FORM.INPUT.PROMOTER_CONSENT", { value: promoter?.name })}
                          checked={!!consentedPromoters.find(consentedPromoter => consentedPromoter === eventInfo?.promoters[i]?.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const found = consentedPromoters.find(consentedPromoter => consentedPromoter === eventInfo?.promoters[i]?.id);
                              if (!found) setConsentedPromoters([...consentedPromoters, eventInfo?.promoters[i]?.id]);
                            } else {
                              setConsentedPromoters(consentedPromoters.filter(consentedPromoter => consentedPromoter !== eventInfo?.promoters[i]?.id));
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                })}
              </Row>

              <Button type="submit" variant="link" className="float-end small text-dark text-decoration-none" disabled={loading}>
                {loading ?
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                  :
                  <>{t("ACCOUNT.FORM.BUTTON.SUBMIT")}</>
                }
              </Button>

              <Row>
                <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
                  {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
                  <Button variant="dark" size="lg" type="button" className="shadow flex-fill" onClick={() => history.push("/services" + history.location.search)}>{t("ACCOUNT.NEXT")}</Button>
                </Navbar>
              </Row>
            </Form>
          )}
        </Formik>

        <Attendant />

        {/* {!user?.email_verified ?
          loadingEmailVerification ?
            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="mt-5" />
            :
            !verifying ?
              <div className="mt-5">
                <span className="text-danger mt-5">{t("ACCOUNT.VERIFY_NEEDED")}</span>
                <div className="mt-3">
                  <span>{t("ACCOUNT.VERIFY_INFO")} </span>
                  <Link to={history.location.pathname + history.location.search} className="text-decoration-none" disabled={loadingGetEmailVerification} onClick={sendEmailVerification}>
                    {loadingGetEmailVerification ?
                      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      :
                      <span> {t("ACCOUNT.VERIFY_NOW")}</span>
                    }
                  </Link>
                  <span>.</span>
                </div>
              </div>
              :
              <div className="mt-5">{t("ACCOUNT.VERIFY_SENDED")} <b>{user.email}</b>, {t("ACCOUNT.VERIFY_FOLLOW_STEPS")}.</div>
          : */}
          <Button variant="dark" size="lg" type="button" className="mt-5 d-none d-lg-block" onClick={() => history.push("/services" + history.location.search)}>{t("ACCOUNT.NEXT")}</Button>
        {/* } */}
      </>

    </>
  );
}
