import React from 'react';
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../components/cart-info/cart-info.slice';
import CartCard from '../../components/cart-card/cart-card';
import EventInfoCard from '../../components/event-info-card/event-info-card';
import styles from './side.module.scss';

export default function Side() {
  const cartItems = useSelector(selectCartItems);

  return (
    <>
      <div className={styles['side'] + " d-none d-lg-block"}>
        {cartItems?.length ? <CartCard /> : <EventInfoCard />}
      </div>
    </>
  );
}