import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  eventInfo: {},
  sessionInfo: {},
  progress: 16,
  loading: true,
};

export const getEventSession = createAsyncThunk('checkout/eventSession', async (eventSession, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/event_sessions/${eventSession}`);
    return response.data;

  } catch (err) {
    if (!err.response) throw err;
    return rejectWithValue(err.response.data);
  }
});

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setProgress: (state, data) => {
      state.progress = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventSession.fulfilled, (state, action) => {
        // console.log("REDUX: EVENT SESSION => ", action?.payload);

        state.loading = false;

        state.sessionInfo = {
          onebox_id: action?.payload?.onebox_id,
          datetime: action?.payload?.datetime,
        }

        state.eventInfo = {
          eventId: action?.payload?.event?.id,
          artist: action?.payload?.event?.artist?.name,
          channel: action?.payload?.event?.channel,
          name: action?.payload?.event?.name,
          onebox_id: action?.payload?.event?.onebox_id,
          promoters: action?.payload?.event?.promoters,
          section1_description: action?.payload?.event?.section1_description,
          section1_title: action?.payload?.event?.section1_title,
          section2_description: action?.payload?.event?.section2_description,
          section2_title: action?.payload?.event?.section2_title,
          section3_description: action?.payload?.event?.section3_description,
          section3_title: action?.payload?.event?.section3_title,
          section4_description: action?.payload?.event?.section4_description,
          section4_title: action?.payload?.event?.section4_title,
          sidebar_color: action?.payload?.event?.sidebar_color,
          sidebar_picture: action?.payload?.event?.sidebar_picture,
          venue: action?.payload?.venue?.name,
        }
        document.title = "Entradas · " + action?.payload?.event?.name;
      })
      .addCase(getEventSession.rejected, (state, action) => {
        window.location.href = process.env.REACT_APP_FRONT_URL;
      })
  },
});

export const { setProgress } = checkoutSlice.actions;
export const selectLoading = (state) => state.checkout.loading;
export const selectEventInfo = (state) => state.checkout.eventInfo;
export const selectSessionInfo = (state) => state.checkout.sessionInfo;
export const selectProgress = (state) => state.checkout.progress;
export default checkoutSlice.reducer;
