import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventInfo, selectSessionInfo, setProgress } from '../../checkout.slice';
import { Row, Navbar, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { getTokenLocalStorage } from '../../../../services/auth';
import { selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../cart-info/cart-info.slice';
import { toggle } from '../../parts/offcanvas/offcanvas.slice';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

export default function Widget() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory();
  const isAuthenticated = getTokenLocalStorage();
  const sessionInfo = useSelector(selectSessionInfo);
  const eventInfo = useSelector(selectEventInfo);
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const hasInsurance = useSelector(selectHasInsurance);
  const queryParams = queryString.parse(history.location.search);
  const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
  const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
  const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
  const cartUpsellings = useSelector(selectCartUpsellings);
  // const release = !currentCartSession?.token;
  // console.log("EVENT onebox_id:", eventInfo?.onebox_id)
  // console.log("SESSION onebox_id:", sessionInfo?.onebox_id)
  // console.log("TOKEN:", currentCartSession?.token)

  function next() {
    if (isAuthenticated) history.push("/account" + history.location.search);
    else history.push("/login" + history.location.search);
  }

  React.useEffect(() => {
    dispatch(setProgress(16));
  }, [dispatch]);

  React.useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      _onebox.moduleLoader.loadWidget("widget-container");
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <h6>{t("WIDGET.TITLE")}</h6>
      </div>

      <div
        id="widget-container"
        className="ob-widget"
        data-module="ob-venue-widget"
        data-version="2"
        data-channel={eventInfo?.channel?.onebox_url_path}
        data-eventid={eventInfo?.onebox_id}
        data-sessionid={sessionInfo?.onebox_id}
        data-language="es_ES"
        data-activatesales="true"
        data-forceview="0"
        // data-release={release ? 'true' : 'false'}
        data-token={currentCartSession?.token}
      />

      {!cartItems?.length ? null : <Row>
        <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
          {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
          <Button variant="dark" size="lg" className="shadow flex-fill" onClick={next}>{t("WIDGET.BUTTON.NEXT")}</Button>
        </Navbar>
      </Row>}
    </>
  );
}
