import { configureStore } from '@reduxjs/toolkit';
import offcanvasReducer from '../features/checkout/parts/offcanvas/offcanvas.slice';
import cartInfoReducer from '../features/checkout/components/cart-info/cart-info.slice';
import authReducer from '../features/checkout/components/auth/auth.slice';
import checkoutReducer from '../features/checkout/checkout.slice';
import orderReducer from '../features/checkout/components/order/order.slice';
import attendantReducer from '../features/checkout/components/attendant/attendant.slice';
import alertReducer from '../features/checkout/components/alert/alert.slice';

export const store = configureStore({
  reducer: {
    offcanvas: offcanvasReducer,
    cartInfo: cartInfoReducer,
    auth: authReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    attendant: attendantReducer,
    alert: alertReducer,
  },
});
