import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as Yup from "yup";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: 'es',
      load: 'languageOnly',
      fallbackLng: 'es',
      whitelist: ['es', 'en', 'ca'],
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    },
  );

export function changeLanguage(newLng) {
  if (i18n.isInitialized && i18n.language !== newLng) {
    i18n.changeLanguage(newLng, loadYupLocale);
  }
}

function loadYupLocale() {
  Yup.setLocale({
    mixed: {
      required: i18n.t("VALIDATION.REQUIRED"),
    },
    string: {
      email: i18n.t("VALIDATION.EMAIL"),
    },
  });
}

i18n.on('initialized', function () {
  loadYupLocale();
});

export default i18n;