import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Col, Image } from 'react-bootstrap';
import { selectEventInfo, selectProgress } from '../../checkout.slice';
import Widget from '../../components/widget/widget';
import Alert from '../../components/alert/alert';
import Payment from '../../components/payment/payment';
import Account from '../../components/account/account';
import Forgot from '../../components/auth/forgot/forgot';
import Recover from '../../components/auth/recover/recover';
import Order from '../../components/order/order';
import Auth from '../../components/auth/auth';
import OrderSuccess from '../../components/order/orderSuccess/orderSuccess';
import OrderError from '../../components/order/orderError/orderError';
import Services from '../../components/services/services';
import Footer from '../footer/footer';
import styles from './main.module.scss';
// import { selectCartItems } from '../../components/cart-info/cart-info.slice';
import { getTokenLocalStorage } from '../../../../services/auth';
import queryString from 'query-string';

export default function Main() {
  const history = useHistory();
  const progress = useSelector(selectProgress);
  const eventInfo = useSelector(selectEventInfo);
  // const cartItems = useSelector(selectCartItems);
  const isAuthenticated = getTokenLocalStorage();
  const queryParams = queryString.parse(history.location.search);

  return (
    <>
      <Col className={styles['main'] + " pb-5 h-100"}>
        <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }} className="pb-5">

          <div className={styles['progress'] + ' progress'}>
            <div className="progress-bar" role="progressbar" style={{ width: progress + '%', backgroundColor: eventInfo?.sidebar_color }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div className="text-center">
            <Image src={eventInfo?.channel?.logo} height="40px" className="mt-3" />
          </div>

          <Switch>
            <Route exact path="/">
              <Widget />
            </Route>
            <Route exact path="/login">
              <Auth />
            </Route>
            <Route exact path="/register">
              <Auth />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            <Route path="/recover">
              <Recover />
            </Route>
            <Route path="/account">
              {isAuthenticated ? <Account /> : <Redirect to={{
                pathname: "/login",
                search: `?eventSession=${queryParams?.eventSession}&redirect=${history.location.pathname}${encodeURIComponent(history.location.search)}`,
              }} />}
            </Route>
            <Route path="/services">
              {/* {!cartItems?.length ? <Redirect to={{
                pathname: "/",
                search: history.location.search,
              }} /> :  */}
              <Services />
              {/* } */}
            </Route>
            <Route exact path="/payment">
              {/* {!cartItems?.length ? <Redirect to={{
                pathname: "/",
                search: history.location.search,
              }} /> :  */}
              <Payment />
              {/* } */}
            </Route>
            <Route exact path="/order">
              <Order />
            </Route>
            <Route exact path="/success">
              {isAuthenticated ? <OrderSuccess /> : <Redirect to={{
                pathname: "/login",
                search: `?eventSession=${queryParams?.eventSession}&redirect=${history.location.pathname}${encodeURIComponent(history.location.search)}`,
              }} />}
            </Route>
            <Route exact path="/error">
              <OrderError />
            </Route>
          </Switch>

          <Footer />
          <Alert />

        </Col>
      </Col>
    </>
  );
}
