import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Navbar, Spinner, Image, Accordion, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { BsChevronLeft } from "react-icons/bs";
import { MdAddShoppingCart } from "react-icons/md";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { toggle } from '../../parts/offcanvas/offcanvas.slice';
import { addInsurance, deleteInsurance, getInsurance, selectCartInsuranceCost, selectCartItems, selectFinalPrice, selectHasInsurance, selectInsurance, selectLoadingGetInsurance, getUpsellings, selectUpsellings, selectLoadingUpsellings, updateUpsellings, selectCartUpsellings } from '../cart-info/cart-info.slice';
import { setProgress, selectEventInfo } from '../../checkout.slice';
import styles from './services.module.scss';


export default function Services() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const upsellings = useSelector(selectUpsellings);
  const cartUpsellings = useSelector(selectCartUpsellings);
  const eventInfo = useSelector(selectEventInfo);
  const cartItems = useSelector(selectCartItems);
  const finalPrice = useSelector(selectFinalPrice);
  const insurance = useSelector(selectInsurance);
  const loadingGetInsurance = useSelector(selectLoadingGetInsurance);
  const loadingUpsellings = useSelector(selectLoadingUpsellings);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);

  const [upsellingTypes, setUpsellingTypes] = useState();
  const [selectedUpsellings, setSelectedUpsellings] = useState(upsellings);

  useEffect(() => {
    dispatch(setProgress(64));
    dispatch(getInsurance());
    dispatch(getUpsellings({ eventId: eventInfo?.eventId, external: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Organize by type
    const upsellingsByType = {};
    upsellings?.forEach(upselling => {
      if (!upsellingsByType[upselling.type]) upsellingsByType[upselling.type] = [];
      upsellingsByType[upselling.type].push(upselling);
    });

    // Sort by type
    const keys = Object.keys(upsellingsByType).sort();
    const upsellingsByTypeSorted = keys.map(key => key);

    setUpsellingTypes(upsellingsByTypeSorted);

    setSelectedUpsellings(upsellings?.map(upselling => {
      return {
        ...upselling,
        selectedName: upselling.variations[0].name,
        selectedColor: upselling.variations[0].color,
        selectedNights: upselling.variations[0].nights,
        selectedQuantity: 1,
        selectedRoom: upselling.variations[0].room,
        selectedSize: upselling.variations[0].size,
      }
    }) || [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsellings]);

  const initialValuesInsurance = {
    services: insurance ? [{ ...insurance, checked: hasInsurance }] : []
  };

  const validationSchemaInsurance = Yup.object().shape({
    services: Yup.array().of(
      Yup.object().shape({
        checked: Yup.bool(),
      })
    )
  });

  const onSubmit = async (values, { setErrors, resetForm }) => {
    history.push("/payment" + history.location.search);
  };

  const handleSubmit = () => {
    const cartItemsFormatted = cartItems.map(item => {
      return {
        item_id: item.id,
        // item_category: item.type,
        price: item.priceBreakdown.basePrice,
        quantity: '1'
      }
    });

    const upsellingsFormatted = cartUpsellings.map(item => {
      return {
        item_id: item.id,
        // item_category: item.type,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity
      }
    });

    const items = cartItemsFormatted.concat(upsellingsFormatted);

    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: items
      }
    });

    history.push("/payment" + history.location.search);
  }

  function getVariation(upselling) {
    const selectedUpselling = selectedUpsellings.find(selectedUpselling => selectedUpselling.id === upselling.id);
    const variations = upsellings?.filter(_upselling => _upselling.type === upselling.type)?.flatMap(_upselling => _upselling.variations) || [];
    let found = null;
    if (upselling.type === 'merchandising') {
      found = variations.find(_variation => _variation.size === selectedUpselling.selectedSize && _variation.color === selectedUpselling.selectedColor);

    } else if (upselling.type === 'hotel') {
      found = variations.find(_variation => _variation.room === selectedUpselling.selectedRoom && _variation.nights === selectedUpselling.selectedNights);
    }

    if (!found) found = variations.find(_variation => _variation.name === selectedUpselling.selectedName);

    return found;
  }

  function getColors(upselling) {
    const selectedUpselling = selectedUpsellings.find(selectedUpselling => selectedUpselling.id === upselling.id);
    let color = [];
    upselling?.variations?.forEach(variation => {
      if (selectedUpselling.selectedSize === variation.size && Boolean(variation.color) && !color.includes(variation.color)) color.push(variation.color);
    });
    return color.map((color, i) => <option key={i} value={color}>{color}</option>);
  }

  function getSizes(upselling) {
    const size = upselling?.variations?.map((variation => variation.size))?.filter(variation => variation !== '');
    return size?.filter((item, index) => size.indexOf(item) === index)?.map((size, i) => <option key={i} value={size}>{size}</option>)
  }

  function getNights(upselling) {
    const selectedUpselling = selectedUpsellings.find(selectedUpselling => selectedUpselling.id === upselling.id);
    let nights = [];
    upselling?.variations?.forEach(variation => {
      if (selectedUpselling.selectedRoom === variation.room && Boolean(variation.nights) && !nights.includes(variation.nights)) nights.push(variation.nights);
    });
    return nights.map((nights, i) => <option key={i} value={nights}>{nights}</option>);
  }

  function getRooms(upselling) {
    const room = upselling?.variations?.map((variation => variation.room))?.filter(variation => variation !== '');
    return room?.filter((item, index) => room.indexOf(item) === index)?.map((room, i) => <option key={i} value={room}>{room}</option>)
  }

  function getNames(upselling) {
    const name = upselling?.variations?.map((variation => variation.name))?.filter(variation => variation !== '');
    return name?.filter((item, index) => name.indexOf(item) === index)?.map((name, i) => <option key={i} value={name}>{name}</option>)
  }

  function addToCart(upselling) {
    const variation = getVariation(upselling);
    if (variation) dispatch(updateUpsellings({ cartUpsellings, variation, quantity: upselling.selectedQuantity }));
  }

  return (
    <>
      <div className="text-center fw-bold mt-4 mb-4 mt-lg-4 mb-lg-5">
        <Link className="float-start cursor-pointer text-dark text-decoration-none" to={"/account" + history.location.search}><BsChevronLeft className="text-dark" /></Link>
        <h6 className="pe-2">{t("SERVICES.TITLE")}</h6>
      </div>

      {loadingGetInsurance || loadingUpsellings ?
        <div className="text-center">
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="mt-5" />
        </div>
        :
        <>

          {/* INSURANCES */}
          <Formik
            initialValues={initialValuesInsurance}
            validationSchema={validationSchemaInsurance}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, touched, errors, handleChange, handleBlur, isValid }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FieldArray name="services">
                  {({ push, remove }) => (
                    <Row>
                      {!!values?.services?.length && values?.services?.map((item, i) => {
                        const checked = `services[${i}].checked`;

                        return <div key={i}>
                          <h6 className='pb-0 mb-0 pt-3'>{'Seguro'}</h6>
                          <Col xs={12} className="ps-3 pe-3 pb-0 pt-0">
                            <Row>
                              <Col className="border-bottom p-3">
                                <Form.Group className="mb-3" controlId={checked}>
                                  <Form.Check
                                    type="checkbox"
                                    label={<b>{values?.services[i].name}</b>}
                                    checked={item?.checked}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        const payload = { insurance_id: values?.services[i]['@id'] };
                                        dispatch(addInsurance(payload));

                                      } else {
                                        dispatch(deleteInsurance());
                                      }
                                      handleChange(e);
                                    }}
                                  />
                                </Form.Group>
                                <div>{values.services[i].description}</div>
                              </Col>
                              <Col xs={3} className="p-3 text-end">
                                <span style={{ fontSize: "1rem" }}>{Number(values.services[i].insuranceCost)}€</span>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      })}
                    </Row>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>

          <div className="mt-3"></div>

          {/* UPSELLINGS */}
          {Boolean(upsellings?.length) &&
            <Row>
              {Boolean(upsellings?.length) && upsellingTypes?.map((upsellingType, upsellingTypeIndex) => {
                return <Accordion key={upsellingTypeIndex} className={styles['accordion']} flush>
                  <Accordion.Item eventKey={upsellingTypeIndex} className={styles['accordion-item']}>
                    <Accordion.Button className={styles['accordion-button']}>{t(upsellingType)}</Accordion.Button>
                    <Accordion.Body className={styles['accordion-body']}>
                      <Row>
                        {upsellings?.map((upselling, upsellingIndex) => {
                          return (upselling.type === upsellingType) && <div key={upsellingIndex}>
                            <Col xs={12}>
                              <Row style={{ display: 'flex', 'alignItems': 'center' }}>
                                <Col xs={12} sm={6} md={4} xl={4} xxl={2} className="pb-2 fw-bold">
                                  <span>{upselling?.name}</span>
                                </Col>
                                <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="pb-2 fw-bold">
                                  <span>{getVariation(upselling)?.price}€</span>
                                </Col>

                                {(upsellingType !== 'merchandising' && upsellingType !== 'hotel') &&
                                  <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                    <FloatingLabel label={t("name")}>
                                      <Form.Select
                                        value={selectedUpsellings?.[upsellingIndex]?.selectedName}
                                        onChange={(e) => setSelectedUpsellings(selectedUpsellings.map(_upselling => _upselling.id === upselling.id ? { ..._upselling, selectedName: e.target.value } : _upselling))}
                                        aria-label={t("name")}
                                      >
                                        {getNames(upselling)}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Col>
                                }

                                {upsellingType === 'merchandising' &&
                                  <>
                                    {Boolean(getSizes(upselling)?.length) &&
                                      <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                        <FloatingLabel label={t("size")}>
                                          <Form.Select
                                            value={selectedUpsellings?.[upsellingIndex]?.selectedSize}
                                            onChange={(e) => setSelectedUpsellings(selectedUpsellings.map(_upselling => _upselling.id === upselling.id ? { ..._upselling, selectedSize: e.target.value, selectedColor: upselling?.variations?.find((_variation => _variation.size === e.target.value))?.color } : _upselling))}
                                            aria-label={t("size")}
                                          >
                                            {getSizes(upselling)}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Col>
                                    }

                                    {Boolean(getColors(upselling)?.length) &&
                                      <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                        <FloatingLabel label={t("color")}>
                                          <Form.Select
                                            value={selectedUpsellings?.[upsellingIndex]?.selectedColor}
                                            onChange={(e) => setSelectedUpsellings(selectedUpsellings.map(_upselling => _upselling.id === upselling.id ? { ..._upselling, selectedColor: e.target.value } : _upselling))}
                                            aria-label={t("color")}
                                          >
                                            {getColors(upselling)}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Col>
                                    }
                                  </>
                                }

                                {upsellingType === 'hotel' &&
                                  <>
                                    {Boolean(getRooms(upselling)?.length) &&
                                      <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                        <FloatingLabel label={t("room")}>
                                          <Form.Select
                                            value={selectedUpsellings?.[upsellingIndex]?.selectedRoom}
                                            onChange={(e) => setSelectedUpsellings(selectedUpsellings.map(_upselling => _upselling.id === upselling.id ? { ..._upselling, selectedRoom: e.target.value, selectedNights: upselling?.variations?.find((_variation => _variation.room === e.target.value))?.nights } : _upselling))}
                                            aria-label={t("room")}
                                          >
                                            {getRooms(upselling)}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Col>
                                    }

                                    {Boolean(getNights(upselling)?.length) &&
                                      <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                        <FloatingLabel label={t("nights")}>
                                          <Form.Select
                                            value={selectedUpsellings?.[upsellingIndex]?.selectedNights}
                                            onChange={(e) => setSelectedUpsellings(selectedUpsellings.map(_upselling => _upselling.id === upselling.id ? { ..._upselling, selectedNights: e.target.value } : _upselling))}
                                            aria-label={t("nights")}
                                          >
                                            {getNights(upselling)}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Col>
                                    }
                                  </>
                                }

                                <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                  <FloatingLabel label={t("quantity")} className="">
                                    <Form.Control
                                      type="number"
                                      min={1}
                                      className="form-control"
                                      value={selectedUpsellings?.[upsellingIndex]?.selectedQuantity}
                                      onChange={(e) => setSelectedUpsellings(selectedUpsellings.map(_upselling => _upselling.id === upselling.id ? { ..._upselling, selectedQuantity: Number(e.target.value) } : _upselling))}
                                    ></Form.Control>
                                  </FloatingLabel>
                                </Col>

                                <Col xs={12} sm={6} md={4} xl={3} xxl={2} className="ps-3 pe-3 pb-3 pt-0">
                                  <Button variant="dark" size="lg" onClick={() => addToCart(selectedUpsellings?.[upsellingIndex])} className="w-100">
                                    <MdAddShoppingCart size={22} />
                                  </Button>
                                </Col>

                                {getVariation(upselling)?.description ?
                                  <div className="mb-3">{getVariation(upselling)?.description}</div>
                                  :
                                  <div className="mb-3">{upselling?.description}</div>
                                }
                                <div>
                                  {getVariation(upselling)?.image1 && <a href={getVariation(upselling)?.image1} target="_blank" rel="noreferrer"><Image style={{ height: '190px', objectFit: 'cover' }} className='mb-3 p-2' src={getVariation(upselling)?.image1} rounded /></a>}
                                  {getVariation(upselling)?.image2 && <a href={getVariation(upselling)?.image2} target="_blank" rel="noreferrer"><Image style={{ height: '190px', objectFit: 'cover' }} className='mb-3 p-2' src={getVariation(upselling)?.image2} rounded /></a>}
                                  {getVariation(upselling)?.image3 && <a href={getVariation(upselling)?.image3} target="_blank" rel="noreferrer"><Image style={{ height: '190px', objectFit: 'cover' }} className='mb-3 p-2' src={getVariation(upselling)?.image3} rounded /></a>}
                                  {getVariation(upselling)?.image4 && <a href={getVariation(upselling)?.image4} target="_blank" rel="noreferrer"><Image style={{ height: '190px', objectFit: 'cover' }} className='mb-3 p-2' src={getVariation(upselling)?.image4} rounded /></a>}
                                </div>
                              </Row>
                            </Col>
                          </div>
                        })}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              })}
            </Row>
          }

          {(!insurance && !upsellings?.length) &&
            <span className="mt-3">{t("SERVICES.NO_SERVICES")}</span>
          }

          <Button variant="dark" size="lg" onClick={() => handleSubmit()} className="d-none d-lg-block mt-3">
            {t("SERVICES.FORM.BUTTON.SUBMIT")}
          </Button>

          <Row>
            <Navbar fixed="bottom" bg="light" className="d-lg-none shadow">
              {cartItems?.length ? <Button variant="light" size="lg" className="shadow flex-fill" style={{ marginRight: '1.25rem' }} onClick={() => dispatch(toggle())}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button> : null}
              <Button variant="dark" size="lg" onClick={() => handleSubmit()} className="shadow flex-fill">
                {t("SERVICES.FORM.BUTTON.SUBMIT")}
              </Button>
            </Navbar>
          </Row>
        </>
      }
    </>
  );
}
