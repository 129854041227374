import React from "react";
import { Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styles from "./alert.module.scss";
import { selectShow, hideAlert, selectVariant, selectDelay, selectMessage } from "./alert.slice";

export default function Alert() {
  const dispatch = useDispatch();
  const show = useSelector(selectShow);
  const variant = useSelector(selectVariant);
  const delay = useSelector(selectDelay);
  const message = useSelector(selectMessage);

  return (
    <Toast bg={variant || 'success'} onClose={() => dispatch(hideAlert())} show={show} delay={delay || 3000} autohide className={styles['toast']}>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
}
