import React from 'react';
import { Card, Accordion, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectEventInfo, selectSessionInfo } from '../../checkout.slice';
import styles from './event-info-card.module.scss';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { hexToRgbA } from '../../../../helpers/utils';

export default function EventInfoCard(props) {
  const { t } = useTranslation();
  const { showOffcanvas, setShowOffcanvas } = props;
  const eventInfo = useSelector(selectEventInfo);
  const sessionInfo = useSelector(selectSessionInfo);

  return (
    <>
      <Card className={styles['event-info-card'] + " shadow"}>
        <Card.Header className={styles['event-info-card-header']} style={{ background: `-webkit-linear-gradient(bottom, ${eventInfo?.sidebar_color} 0%, ` + hexToRgbA(eventInfo?.sidebar_color) + ` 30%), url(${eventInfo?.sidebar_picture}) no-repeat center/cover` }}></Card.Header>
        <Card.Body className={!showOffcanvas ? styles['event-info-card-body'] : styles['event-info-card-body-offcanvas']} style={{ backgroundColor: eventInfo?.sidebar_color }}>

          <h3 className="text-light">{eventInfo?.artist + ' · ' + eventInfo?.name}</h3>
          <div className="text-light mb-1">{sessionInfo?.datetime ? format(new Date(sessionInfo?.datetime), "d 'de' MMMM 'de' yyyy · HH:mm'h'", { locale: es, timeZone: 'Europe/Madrid' }) : ''}</div>
          <div className="text-light mb-4 text-capitalize">{eventInfo?.venue}</div>

          <Accordion defaultActiveKey="" flush className={styles['accordion']}>

            {eventInfo?.section1_title && eventInfo?.section1_description &&
              <Accordion.Item eventKey="0" className={styles['accordion-item']} style={{ backgroundColor: eventInfo?.sidebar_color }}>
                <Accordion.Button className={styles['accordion-button']}>{eventInfo?.section1_title}</Accordion.Button>
                <Accordion.Body className={styles['accordion-body']} style={{ backgroundColor: eventInfo?.sidebar_color, whiteSpace: 'pre-wrap' }}>
                  {eventInfo?.section1_description}
                </Accordion.Body>
              </Accordion.Item>
            }

            {eventInfo?.section2_title && eventInfo?.section2_description &&
              <Accordion.Item eventKey="1" className={styles['accordion-item']} style={{ backgroundColor: eventInfo?.sidebar_color }}>
                <Accordion.Button className={styles['accordion-button']}>{eventInfo?.section2_title}</Accordion.Button>
                <Accordion.Body className={styles['accordion-body']} style={{ backgroundColor: eventInfo?.sidebar_color, whiteSpace: 'pre-wrap' }}>
                  {eventInfo?.section2_description}
                </Accordion.Body>
              </Accordion.Item>
            }

            {eventInfo?.section3_title && eventInfo?.section3_description &&
              <Accordion.Item eventKey="2" className={styles['accordion-item']} style={{ backgroundColor: eventInfo?.sidebar_color }}>
                <Accordion.Button className={styles['accordion-button']}>{eventInfo?.section3_title}</Accordion.Button>
                <Accordion.Body className={styles['accordion-body']} style={{ backgroundColor: eventInfo?.sidebar_color, whiteSpace: 'pre-wrap' }}>
                  {eventInfo?.section3_description}
                </Accordion.Body>
              </Accordion.Item>
            }

            {eventInfo?.section4_title && eventInfo?.section4_description &&
              <Accordion.Item eventKey="3" className={styles['accordion-item']} style={{ backgroundColor: eventInfo?.sidebar_color }}>
                <Accordion.Button className={styles['accordion-button']}>{eventInfo?.section4_title}</Accordion.Button>
                <Accordion.Body className={styles['accordion-body']} style={{ backgroundColor: eventInfo?.sidebar_color, whiteSpace: 'pre-wrap' }}>
                  {eventInfo?.section4_description}
                </Accordion.Body>
              </Accordion.Item>
            }

          </Accordion>
        </Card.Body>

        <div style={{ background: `-webkit-linear-gradient(bottom, ${eventInfo?.sidebar_color} 0%, ` + hexToRgbA(eventInfo?.sidebar_color) + ` 75%)`, height: '100px', position: 'absolute', bottom: showOffcanvas ? '56px' : 0, pointerEvents: 'none', right: '12px', left: '12px' }}></div>

        {showOffcanvas &&
          <Card.Footer className={styles['event-info-card-footer']} style={{ backgroundColor: eventInfo?.sidebar_color }}>
            <div className="d-grid gap-2">
              <Button variant="light" size="lg" className="shadow flex-fill" onClick={() => setShowOffcanvas(false)}>{t("EVENT_INFO_OFFCANVAS.BUTTON.NEXT")}</Button>
            </div>
          </Card.Footer>
        }
      </Card>
    </>
  );
}