import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from './order.slice';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { showAlert } from '../alert/alert.slice';
import { setProgress } from '../../checkout.slice';
import { selectCartUpsellings } from '../cart-info/cart-info.slice';
import { useTranslation } from 'react-i18next';

export default function Checkout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const cartUpsellings = useSelector(selectCartUpsellings);

  React.useEffect(() => {
    dispatch(setProgress(100));
  }, [dispatch]);

  React.useEffect(() => {
    const queryParams = queryString.parse(history.location.search);
    const paytpvToken = queryParams?.paytpvToken;
    const eventSession = queryParams?.eventSession;
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);
    const shoppingCartToken = currentCartSession?.token;

    if (!paytpvToken || !shoppingCartToken) {
      dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.PAYMENT_INVALID_TOKEN") }));
      history.push("/?eventSession=" + eventSession);

    } else {
      const payload = {
        shopping_cart_token: shoppingCartToken,
        event_session_id: Number(eventSession),
        pay_tpv_token: paytpvToken,
        upselling_variations: cartUpsellings?.map(cartVariation => {
          return {
            id: cartVariation.id,
            quantity: cartVariation.quantity
          }
        })
      }

      dispatch(createOrder(payload)).then((response) => {
        if (response?.error) {
          if (Array.isArray(response?.payload)) dispatch(showAlert({ variant: 'danger', message: response?.payload?.join(', ') }));
          else dispatch(showAlert({ variant: 'danger', message: t("ALERT.ERROR.ORDER") }));
          history.push("/error?eventSession=" + eventSession);

        } else if (response?.payload?.payment_challenge_url) {
          window.location.href = response?.payload?.payment_challenge_url;

        } else {
          history.push("/success?eventSession=" + eventSession);
        }
      });
    }
  }, [dispatch, history, history.location.search, t, cartUpsellings]);

  return (
    <>
      <div className="d-flex mt-5 text-center" style={{ height: "98%" }}>
        <Spinner className="mx-auto align-self-center" as="span" animation="grow" size="lg" role="status" aria-hidden="true" />
      </div>
    </>
  );
}
