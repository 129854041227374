import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  variant: 'success',
  delay: 4000,
  message: '',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, data) => {
      state.variant = data.payload.variant || initialState.variant;
      state.delay = data.payload.delay || initialState.delay;
      state.message = data.payload.message || initialState.message;
      state.show = true;
    },
    hideAlert: (state) => {
      state.show = false;
      state.variant = initialState.variant;
      state.delay = initialState.delay;
      state.message = initialState.message;
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export const selectShow = (state) => state.alert.show;
export const selectVariant = (state) => state.alert.variant;
export const selectDelay = (state) => state.alert.delay;
export const selectMessage = (state) => state.alert.message;
export default alertSlice.reducer;
