export function setTokenLocalStorage(token) {
  if (typeof (Storage) !== 'undefined') {
    localStorage?.setItem("bailaTok", token);
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function getTokenLocalStorage() {
  if (typeof (Storage) !== 'undefined') {
    return localStorage?.getItem("bailaTok");
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function removeTokenLocalStorage() {
  if (typeof (Storage) !== 'undefined') {
    localStorage?.removeItem("bailaTok");
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function setUserLocalStorage(user) {
  if (typeof (Storage) !== 'undefined') {
    localStorage?.setItem("bailaUser", JSON.stringify(user));
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function getUserLocalStorage() {
  if (typeof (Storage) !== 'undefined') {
    return JSON.parse(localStorage?.getItem("bailaUser"));
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function removeUserLocalStorage() {
  if (typeof (Storage) !== 'undefined') {
    localStorage?.removeItem("bailaUser");
  } else {
    console.warn('Warning: localStorage is not available');
  }
}