import axios from 'axios';
import i18next from 'i18next';
import { getTokenLocalStorage, removeTokenLocalStorage, removeUserLocalStorage } from '../services/auth';

axios.defaults.baseURL = process.env.REACT_APP_API_URL + '/api'
axios.defaults.timeout = 40000;

axios.interceptors.request.use(function (config) {
  const token = getTokenLocalStorage();
  if (token) config.headers['Authorization'] = `Token ${token}`;
  if (i18next.language) config.headers['Accept-Language'] = i18next.language;
  return config;

}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;

}, function (error) {
  if (401 === error.response.status) {
    removeTokenLocalStorage();
    removeUserLocalStorage();
  }
  return Promise.reject(error);
});