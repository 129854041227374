import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Main from './parts/main/main';
import Side from './parts/side/side';
import Offcanvas from './parts/offcanvas/offcanvas';
import { getShoppingCart, updateCartItems, removeCartItems, selectCartItems, getInsurance } from './components/cart-info/cart-info.slice';
import { getEventSession, selectLoading } from './checkout.slice';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import EventInfoOffcanvas from './components/event-info-offcanvas/event-info-offcanvas';
import { showAlert } from './components/alert/alert.slice';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/baila_monogram_color.svg';

export default function Checkout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectLoading);
  const cartItems = useSelector(selectCartItems);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const queryParams = queryString.parse(history.location.search);
  if (!queryParams?.eventSession) window.location.href = process.env.REACT_APP_FRONT_URL;

  const handleResize = (e) => setWindowWidth(window.innerWidth);
  React.useEffect(() => window.addEventListener("resize", handleResize), []);

  React.useEffect(() => {
    const bailaCartSessions = localStorage?.getItem("bailaCartSessions");
    const cartSessions = bailaCartSessions ? JSON.parse(bailaCartSessions) : [];
    const currentCartSession = cartSessions.find(cartSession => cartSession.eventSession === queryParams?.eventSession);

    if (currentCartSession?.token) dispatch(getShoppingCart());

    dispatch(getEventSession(queryParams?.eventSession)).then(response => {
      try {
        const script = document.createElement("script");
        script.src = `${process?.env?.REACT_APP_ONEBOX_WIDGET_URL}/${response?.payload?.event?.channel?.onebox_url_path}/widgets/ob-widgets?rootPath=${process?.env?.REACT_APP_ONEBOX_API_URL}`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          if (history.location.pathname === "/") {
            // eslint-disable-next-line no-undef
            _onebox.moduleLoader.loadWidget("widget-container");
          }
          // eslint-disable-next-line no-undef
          pubsub.subscribe('/cart/updated', function (e) {
            console.log("PUBSUB /cart/updated", e.data)
            dispatch(updateCartItems(e.data));
            dispatch(getInsurance());
          });
          // eslint-disable-next-line no-undef
          pubsub.subscribe('/cart/expired', function (e) {
            // console.log('EVENT: Cart expired =>', e);
            dispatch(showAlert({ variant: 'warning', message: t("ALERT.WARNING.CART_EXPIRED") }));
            dispatch(removeCartItems());
            history.push("/?eventSession=" + queryParams?.eventSession);
          });
        };

        dispatch(getInsurance());

        return () => {
          document.body.removeChild(script);
        }
      } catch (error) { }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container-fluid h-100">
        <div className="row h-100">
          {loading ?
            <img className="logo-bounce" src={logo} alt="" width="200" height="200"></img>
            :
            <>
              <Main />
              <Side />
              <Offcanvas />
              {history.location.pathname === '/' && windowWidth < 992 && !cartItems?.length && <EventInfoOffcanvas />}
            </>
          }
        </div>
      </div>
    </>
  );
}
