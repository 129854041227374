import React from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import { Button, Card, Navbar } from 'react-bootstrap';
import CartInfo from '../cart-info/cart-info';
import styles from './cart-card.module.scss';
import { getTokenLocalStorage } from '../../../../services/auth';
import { useSelector } from 'react-redux';
import { selectEventInfo } from '../../checkout.slice';
import { useTranslation } from 'react-i18next';
import { selectCartInsuranceCost, selectFinalPrice, selectHasInsurance, selectCartUpsellings } from '../cart-info/cart-info.slice';

export default function CartCard() {
  const { t } = useTranslation();
  const history = useHistory();
  const isAuthenticated = getTokenLocalStorage();
  const eventInfo = useSelector(selectEventInfo);
  const finalPrice = useSelector(selectFinalPrice);
  const hasInsurance = useSelector(selectHasInsurance);
  const cartInsuranceCost = useSelector(selectCartInsuranceCost);
  const cartUpsellings = useSelector(selectCartUpsellings);

  function next() {
    if (isAuthenticated) history.push("/account" + history.location.search);
    else history.push("/login" + history.location.search);
  }

  return (
    <>
      <Card className={styles['cart-card'] + " shadow"}>
        {/* <Card.Header className={styles['cart-card-header']}></Card.Header> */}
        <Card.Img className={styles['cart-card-img']} variant="top" src={eventInfo?.sidebar_picture} />
        <Card.Body className={styles['cart-card-body']}>
          <CartInfo />
        </Card.Body>
        <Card.Footer className={styles['cart-card-footer']}>
          <div className="d-grid gap-2">
            <Switch>
              <Route exact path="/">
                <Navbar bg="light" className="bg-white">
                  <Button variant="light" size="lg" className="flex-fill pe-none" style={{ marginRight: '1rem' }}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button>
                  <Button variant="dark" size="lg" className="flex-fill" onClick={() => next()}>{t("CART_CARD.BUTTON.NEXT")}</Button>
                </Navbar>
              </Route>
              <Route exact path="/payment">
                <Button variant="light" size="lg" className="fw-bold" onClick={() => history.push("/account" + history.location.search)}>{t("CART_CARD.BUTTON.EDIT_DATA")}</Button>
                <Navbar bg="light" className="bg-white">
                  <Button variant="light" size="lg" className="flex-fill pe-none" style={{ marginRight: '1rem' }}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button>
                  <Button variant="light" size="lg" className="fw-bold flex-fill" onClick={() => history.push("/" + history.location.search)}>{t("CART_CARD.BUTTON.EDIT_CART")}</Button>
                </Navbar>
              </Route>
              <Route exact path="/login">
                {isAuthenticated && <Button variant="dark" size="lg" onClick={next}>{t("CART_CARD.BUTTON.NEXT")}</Button>}
                <Navbar bg="light" className="bg-white">
                  <Button variant="light" size="lg" className="flex-fill pe-none" style={{ marginRight: '1rem' }}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button>
                  <Button variant="light" size="lg" className="fw-bold flex-fill" onClick={() => history.push("/" + history.location.search)}>{t("CART_CARD.BUTTON.EDIT_CART")}</Button>
                </Navbar>
              </Route>
              <Route>
                <Navbar bg="light" className="bg-white">
                  <Button variant="light" size="lg" className="flex-fill pe-none" style={{ marginRight: '1rem' }}>{(Number(finalPrice) + (hasInsurance ? Number(cartInsuranceCost) : 0) + (cartUpsellings?.reduce((acumulator, currentValue) => Number(acumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0))).toFixed(2)} €</Button>
                  <Button variant="light" size="lg" className="fw-bold flex-fill" onClick={() => history.push("/" + history.location.search)}>{t("CART_CARD.BUTTON.EDIT_CART")}</Button>
                </Navbar>
              </Route>
            </Switch>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
}